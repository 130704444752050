import { OPTION_ITEM_ALL, OPTION_ITEM_ONLYFAB365, OPTION_ITEM_GENERAL } from './constant';
import { useState, useEffect, useRef } from 'react';
import styled from 'styled-components';

const SelectType = ({ value, onchange, renderComponent }) => {
  const [isOpen, setIsOpen] = useState(false);
  const dropdownRef = useRef(null);

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  const select = e => {
    onchange(e.target.dataset.value);
    setIsOpen(false);
  };

  const handleClickOutside = event => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      setIsOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  return (
    <Dropdown ref={dropdownRef}>
      <DropdownToggle onClick={toggleDropdown}>
        {value === OPTION_ITEM_ALL
          ? 'All'
          : value === OPTION_ITEM_ONLYFAB365
          ? 'Fab365 Only'
          : 'General'}
        <img src='/assets/img/designers/icon_down.png' alt='' />
      </DropdownToggle>
      {isOpen && (
        <DropdownMenu renderComponent={renderComponent}>
          <DropdownItem
            data-value={OPTION_ITEM_ALL}
            onClick={select}
            className={value === OPTION_ITEM_ALL ? 'selected' : ''}
          >
            All
          </DropdownItem>
          <DropdownItem
            data-value={OPTION_ITEM_ONLYFAB365}
            onClick={select}
            className={value === OPTION_ITEM_ONLYFAB365 ? 'selected' : ''}
          >
            Fab365 Only
          </DropdownItem>
          <DropdownItem
            data-value={OPTION_ITEM_GENERAL}
            onClick={select}
            className={value === OPTION_ITEM_GENERAL ? 'selected' : ''}
          >
            General
          </DropdownItem>
        </DropdownMenu>
      )}
    </Dropdown>
  );
};

export default SelectType;

export const Dropdown = styled.div`
  position: relative;
  min-width: 103px;
  height: 36px;
  border: 1px solid #6e6e6e;
  line-height: 36px;
  border-radius: 10px;
  font-weight: 500 !important;
`;

export const DropdownToggle = styled.button`
  background-color: #f0f0f0;
  border: 1px solid #ccc;
  padding: 10px;
  cursor: pointer;
  width: 100%;
  text-align: center !important;
  font-family: 'Inter', sans-serif !important;
  font-size: 16px !important;
  font-weight: 500 !important;
  color: #000 !important;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  padding: 0 20px !important;
  font-weight: 500 !important;

  img {
    width: 8px;
    margin-left: 6px;
    margin-bottom: 2px;
  }
`;

export const DropdownMenu = styled.div`
  position: absolute;
  background-color: #fff;
  border: 1px solid #6e6e6e;
  z-index: 100;
  list-style: none;
  margin: 0;
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
  flex-direction: column;
  text-align: right;
  font-weight: 500 !important;
  top: 41px;
  right: 0px;
  border-radius: 10px;
  box-sizing: border-box;
  /* border: 1px solid red; */
  font-family: 'Inter', sans-serif !important;
  font-weight: 500 !important;
  padding: 12px 18px !important;
  width: 161px !important;
`;

export const DropdownItem = styled.div`
  padding: 10px;
  cursor: pointer;

  /* border: 1px solid red; */
  width: 100%;
  height: 36px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  font-family: 'Inter', sans-serif !important;
  font-weight: 500 !important;
  font-size: 16px !important;
`;
