import React, { useContext, useEffect, useState } from 'react';
import Layout from '../../component/layout/Layout';
import MainJumbotron from './component/MainJumbotron';
import { Webpage2StylesContainer } from '../../styles/Webpage2Styles';
import HomeInfo from './component/HomeInfo';
import { Ss, ls } from '../../constant';
import ModalContext from '../../context/modal/modal';
import { useLocation, useNavigate } from 'react-router-dom';
import { ITEMS } from '../../route/constant';
import ToTheTop from './component/ToTheTop';

import { useRecoilState } from 'recoil';
import { isMobileAtom } from '../../atom/atom';

function Main2() {
  const [isMobile, setIsMobile] = useRecoilState(isMobileAtom);
  const [showToTheTop, setShowToTheTop] = useState(false);

  const { state, action } = useContext(ModalContext);

  const scrollY = localStorage.getItem(ls.prevScrollY);
  const location = useLocation();
  const navigate = useNavigate();

  const isLogin =
    localStorage.getItem(ls.accessToken) !== undefined &&
    localStorage.getItem(ls.accessToken) !== 'undefined' &&
    localStorage.getItem(ls.accessToken) !== null;

  // useEffect(() => {
  //   if (scrollY) {
  //     setTimeout(() => {
  //       window.scrollTo({
  //         top: Number(scrollY),
  //       });
  //     }, 600);
  //   } else {
  //     window.scrollTo(0, 0);
  //   }
  // }, []);

  useEffect(() => {
    if (state.isLoginForInvite && !isLogin) {
      action.setIsLogin(true);
      action.setIsLoginForInvite(false);
    }
  }, [state.isLoginForInvite]);

  // 랜딩페이지 통해서 왔을 경우 url에 있는 초대 토큰 세션스토리지 저장
  const getInviteToken = () => {
    const urlParams = new URLSearchParams(location.search);
    const inviteToken = urlParams.get('t');

    if (!inviteToken) return;

    if (inviteToken !== undefined) {
      sessionStorage.setItem(Ss.inviteToken, inviteToken);
    }
  };

  // 랜딩페이지 통해서 왔을 경우 url에 있는 아이템 넘버 세션스토리지 저장
  const getItemNumber = () => {
    const urlParams = new URLSearchParams(location.search);
    const itemNumber = urlParams.get('item');

    if (!itemNumber) return;

    if (itemNumber !== undefined) {
      sessionStorage.setItem(Ss.itemNumber, itemNumber);
    }
  };

  useEffect(() => {
    getInviteToken();
    getItemNumber();

    // 아이템 페이지네이션, sort, type filter 값 초기화
    localStorage.removeItem(ls.itemPage);
    localStorage.removeItem(ls.itemSort);
    localStorage.removeItem(ls.itemType);
  }, []);

  useEffect(() => {
    // 홈 Url에 초대토큰이 있고 로그아웃 상태일 경우 회원가입 모달창 표시
    if (sessionStorage.getItem(Ss.inviteToken) && !isLogin) {
      action.setIsSignup(true);
    }
  }, [sessionStorage.getItem(Ss.inviteToken), localStorage.getItem(ls.accessToken)]);

  useEffect(() => {
    // 렌딩페이지에서 아이템 다운로드 클릭 시 + 로그인 되어 있을 때 아이템 페이지로 바로 이동
    if (sessionStorage.getItem(Ss.itemNumber) && isLogin) {
      navigate(`${ITEMS}/${sessionStorage.getItem(Ss.itemNumber)}`);
      sessionStorage.removeItem(Ss.itemNumber);
    }
  }, [sessionStorage.getItem(Ss.itemNumber), localStorage.getItem(ls.accessToken)]);

  // 반응형 감지
  useEffect(() => {
    const handleResize = () => {
      // 너비가 700px 이하인 경우를 모바일로 판단
      setIsMobile(window.innerWidth <= 700);
    };

    window.addEventListener('resize', handleResize);

    handleResize(); // 초기 렌더링 시 한 번 실행

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [window.innerWidth]);

  //
  function onScroll() {
    if (window.scrollY > 300) setShowToTheTop(true);
    else setShowToTheTop(false);
  }
  useEffect(() => {
    window.addEventListener('scroll', onScroll);

    return () => {
      window.removeEventListener('scroll', onScroll);
    };
  }, []);

  const preventClose = e => {
    const progressModalY = localStorage.getItem('progressModalY');
    if (progressModalY) return;

    localStorage.setItem('refreshPage', 'refresh');
    window.scrollTo(0, 0);
  };

  // 브라우저에 렌더링 시 한 번만 실행하는 코드
  useEffect(() => {
    (() => {
      window.addEventListener('beforeunload', preventClose);
    })();

    return () => {
      window.removeEventListener('beforeunload', preventClose);
    };
  }, []);

  return (
    <>
      <Layout>
        <Webpage2StylesContainer>
          <div className='allWrap allWrap--padding'>
            {/* <Selectefile /> */}

            <MainJumbotron isMobile={isMobile} />
            <HomeInfo isMobile={isMobile} isLogin={isLogin} />
          </div>
        </Webpage2StylesContainer>
      </Layout>
      {showToTheTop && <ToTheTop />}
    </>
  );
}

export default Main2;
