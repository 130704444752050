/* eslint-disable jsx-a11y/anchor-is-valid */
import { useContext, useState, useEffect } from 'react';
import ModalContext from '../../context/modal/modal';
import UserContext from '../../context/user/user';
import './Login.css';
import { login, resendVerifyEmail } from '../../action/request';
import Apple from '../sns/Apple';
import Twitter from '../sns/Twitter';
import Github from '../sns/Github';
import Google from '../sns/Google';
import Yahoo from '../sns/Yahoo';
import Facebook from '../sns/Facebook';
import { Ss, ls } from '../../constant';
import LangContext from '../../context/lang/lang';
import { loginSignupEn, loginSignupKo } from '../../context/lang/constant';
import { validateEmail } from '../../util/validate';
import { useLocation, useNavigate } from 'react-router';
import { gtagOnClickLogin } from '../../action/gTag';
import { ITEMS } from '../../route/constant';
import { useRecoilState } from 'recoil';
import ErrMessage from '../errmessage/ErrMessage';
import axios from 'axios';
import {
  preDesignerListAtom,
  designerListAtom,
  sortStateAtom,
  randomSortStateAtom,
  idsAtom,
} from '../../atom/atom';
import {
  BottomBox,
  ButtonWrapper,
  H2Style,
  HaveAccount,
  LoginButton,
  LoginContainer,
  LoginInputWrapper,
  LoginTitleWrapper,
  NewToFAB365,
  SignUpHr,
  SocialButton,
  StayLoggedIn,
} from './style/SignupStyle';
import { useCookies } from 'react-cookie';

const Login = ({ appValue }) => {
  const [password, setPassword] = useState('');
  const [email, setEmail] = useState('');
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [resend, setResend] = useState(false);
  const { state, action } = useContext(ModalContext);
  const { action: userAction } = useContext(UserContext);
  const { state: langState } = useContext(LangContext);
  const [, setPreDesignerList] = useRecoilState(preDesignerListAtom);
  const [, setRandomValue] = useRecoilState(randomSortStateAtom);
  const [, setDesignerList] = useRecoilState(designerListAtom);
  const [, setSortState] = useRecoilState(sortStateAtom);
  const [, setsIds] = useRecoilState(idsAtom);
  const isKorean = langState.lang.includes('ko');
  const loginSignupMessages = isKorean ? loginSignupKo : loginSignupEn;
  const isLandingPage = useLocation().pathname.includes('/landing');
  const [autoLogin, setAutoLogin] = useState(false);
  const [cookies, setCookie, removeCookie] = useCookies(['autoLogin']);

  const navigate = useNavigate();

  useEffect(() => {
    userAction.setIsApp(appValue);
  }, []);

  const openModal = () => {
    setModalIsOpen(true);
  };

  const closeModal = () => {
    setModalIsOpen(false);
    action.setIsLogin(false);
  };

  const handleConfirm = async () => {
    setResend(true);
    const result = await resendVerifyEmail(email);
    if (result) {
      setResend(false);
      closeModal();
    }
  };

  const handleFocus = e => {
    if (e.target.name === 'email') {
      setEmail('');
    } else if (e.target.name === 'password') {
      setPassword('');
    }
  };

  const tryLogin = async () => {
    if (!email.trim().length) return alert(loginSignupMessages.LOGIN_ERROR_EMAIL_UNSET);
    if (!validateEmail(email)) return alert(loginSignupMessages.LOGIN_ERROR_EMAIL_INVALID);
    if (!password.trim().length) return alert(loginSignupMessages.LOGIN_ERROR_PASSWORD_NEED);
    const from = appValue ? 'M' : cookies.autoLogin ? 'A' : 'W';
    setAutoLogin(false);
    try {
      const {
        data: { data, message, errCode },
      } = await login({
        action: 'auth-login',
        from: from,
        params: { email: email.trim(), password: password.trim() },
      });

      // gtag 일반 로그인 추적 태그
      gtagOnClickLogin('Fab365');
      if (message) {
        if (errCode === '2') {
          openModal(true);
        } else {
          alert(message);
        }
      } else {
        setDesignerList(false);
        setPreDesignerList(false);

        setSortState({
          sortType: 'Random',
          sortDir: 'Default',
          randomSort: true,
          total: 0,
          idsIndex: 0,
        });
        setsIds('');
        setRandomValue(true);

        const accessToekn = data.token.access_token;
        const refreshToken = data.token.refresh_token;
        localStorage.removeItem(ls.accessToken);
        localStorage.removeItem(ls.refreshToken);
        sessionStorage.removeItem(Ss.inviteToken);

        localStorage.setItem(ls.accessToken, accessToekn);
        localStorage.setItem(ls.refreshToken, refreshToken);

        userAction.setEmail(data.userInfo.email);
        userAction.setId(data.userInfo.id);
        userAction.setIsLogin(true);
        // userAction.setIsApp(appValue);
        //recoil 로그인상태 저장

        userAction.setThumbnail(data.userInfo.thumbnail);
        action.setIsLogin(false);

        handleMotilink(data);

        // 렌딩페이지에서 로그인 시 홈으로 이동
        if (isLandingPage) {
          navigate('/');
        }

        if (appValue) {
          navigate('/appdesigners/ik3d');
          // navigate('/');
        }

        if (sessionStorage.getItem(Ss.itemNumber)) {
          navigate(`${ITEMS}/${sessionStorage.getItem(Ss.itemNumber)}`);
          sessionStorage.removeItem(Ss.itemNumber);
        }
      }
    } catch (e) {}
  };

  const handleKeyPress = event => {
    if (event.key === 'Enter') {
      tryLogin();
    }
  };

  const handleMotilink = async loginData => {
    // const response = await await axios.get('motilink://signIn',{
    //   params:{
    //     //url 뒤에 붙는 param id값
    //     name: loginData?.userInfo?.username,
    //     accessToken: loginData?.token?.access_token,
    //     refreshToken: loginData?.token?.refreshToken,
    //     email: loginData?.userInfo?.email,
    //     expirationDate: loginData?.expirationDate,
    //   }
    // });
  };
  return (
    <>
      <div className={'modal' + (state.isLogin ? ' active' : '')} id='modalLogIn'>
        <div className='modal-inner'>
          <div className='modal-box'>
            {modalIsOpen ? (
              <div>
                <ErrMessage onRequestClose={closeModal} onConfirm={handleConfirm} resend={resend} />
              </div>
            ) : (
              ''
            )}

            <LoginContainer>
              <LoginTitleWrapper>
                <H2Style>Login</H2Style>
                <NewToFAB365>
                  <p> New to FAB365?</p>
                  <a
                    onClick={() => {
                      action.setIsLogin(false);
                      action.setIsSignup(true);
                    }}
                    className='txt-green remodal-signup'
                  >
                    <h3> Sign up</h3>
                  </a>
                </NewToFAB365>
              </LoginTitleWrapper>
              <SignUpHr page='login' />
              <ButtonWrapper>
                <Google />
                <Facebook />
                <Apple />
                <Twitter />
                <Yahoo />
                <Github />
              </ButtonWrapper>
              <div className='modal-box-body'>
                <div className='clearfix'>
                  <div className='pull-right'></div>
                </div>
                <hr className='or' />
                <form action='' name='login_form' className='signup-form m-b-md'>
                  <LoginInputWrapper>
                    <input
                      type='text'
                      placeholder='Email address'
                      className='form-input'
                      name='email'
                      value={email}
                      onChange={e => setEmail(e.target.value)}
                      onKeyDown={handleKeyPress}
                      onFocus={e => handleFocus(e)}
                    />
                    <input
                      type='password'
                      placeholder='Password'
                      className='form-input'
                      name='password'
                      value={password}
                      onChange={e => setPassword(e.target.value)}
                      onKeyDown={handleKeyPress}
                      onFocus={e => handleFocus(e)}
                    />
                  </LoginInputWrapper>
                  <LoginButton
                    type='button'
                    className='btn btn-full btn-fill login'
                    onClick={tryLogin}
                  >
                    Login
                  </LoginButton>
                </form>
                <BottomBox>
                  <StayLoggedIn>
                    <input
                      type='checkbox'
                      id='stay-login'
                      checked={autoLogin}
                      onChange={e => {
                        setCookie('autoLogin', e.target.checked, {
                          path: '/',
                          maxAge: 2 * 365 * 24 * 60 * 60,
                        });
                        setAutoLogin(e.target.checked);
                      }}
                    />
                    <label htmlFor='stay-login'>Stay logged in</label>
                  </StayLoggedIn>
                  <HaveAccount>
                    <h4>Forgot</h4>
                    <a
                      onClick={() => {
                        action.setIsLogin(false);
                        action.setIsForgotPassword(true);
                      }}
                      className='txt-green remodal-forgotPassword'
                    >
                      Password?
                    </a>
                  </HaveAccount>
                </BottomBox>
              </div>
              {appValue ? (
                ''
              ) : (
                <a
                  onClick={() => action.setIsLogin(false)}
                  data-hide-modal='true'
                  className='btn-close'
                >
                  Close
                </a>
              )}
            </LoginContainer>
          </div>
        </div>
      </div>
    </>
  );
};

export default Login;
