import { useEffect, useRef, useState } from 'react';
import styled from 'styled-components';

const DesignerSectionDropDown = ({ handleSortType, options, value, sortValue, typeBtn, width }) => {
  const [isOpen, setIsOpen] = useState(false);

  const dropdownRef = useRef(null);

  useEffect(() => {
    const handleClickOutside = e => {
      if (dropdownRef.current && !dropdownRef.current.contains(e.target)) {
        setIsOpen(false);
      }
    };

    document.addEventListener('click', handleClickOutside);

    return () => {
      document.removeEventListener('click', handleClickOutside);
    };
  }, []);
  return (
    <DesignerSectionDropDownStyle id='DesignerSectionDropDown' ref={dropdownRef} width={width}>
      <ButtonWrapper onClick={() => setIsOpen(!isOpen)} disabled={typeBtn}>
        <ButtonStyle>{sortValue}</ButtonStyle>
        <img src='/assets/img/designers/icon_down.png' alt='' />
      </ButtonWrapper>
      {isOpen && (
        <UlStyle>
          {options.map((option, index) => {
            return (
              <LiStyle
                key={index}
                onClick={() => {
                  handleSortType(option, value);
                  setIsOpen(false);
                }}
              >
                {option}
              </LiStyle>
            );
          })}
        </UlStyle>
      )}
    </DesignerSectionDropDownStyle>
  );
};

const DesignerSectionDropDownStyle = styled.div`
  position: relative;
  display: inline-block;
  text-align: center;
  min-width: 103px;

  padding: 0px 24px;
  height: 38px;
  font-size: 16px;
  border: 1px solid #6e6e6e;
  border-radius: 10px;
  background-color: #fff;

  white-space: nowrap;
  cursor: pointer;
`;

const LiStyle = styled.li`
  cursor: pointer;
  height: 36px;
  font-family: 'Inter';
  font-size: 16px;
  color: #171717;
  font-weight: 500;

  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding: 0px 40px !important;
`;

const ButtonWrapper = styled.button`
  width: 100%;
  height: 100%;
  font-size: 16px !important;
  position: relative;
  display: inline-flex;
  justify-content: center;
  align-items: center;

  img {
    width: 8px;
    margin-left: 6px;
    margin-bottom: 2px;
  }
`;

const ButtonStyle = styled.div`
  border: none;
  font-weight: 500 !important;
  color: rgb(23, 23, 23) !important;
  font-size: 16px !important;
  font-family: 'Inter';
`;

const UlStyle = styled.ul`
  background-color: #fff;
  text-align: right;
  font-weight: 500 !important;
  border: 1px solid #6e6e6e;
  position: absolute;
  top: 41px;
  right: -2px;
  border-radius: 10px;
  z-index: 100;
  box-sizing: border-box;
  padding: 12px 0px !important;
`;

export default DesignerSectionDropDown;
