import { useContext, useEffect, useState } from 'react';
import ModalContext from '../../../context/modal/modal';
import { MY_CREDIT } from '../../../route/constant';
import { useNavigate } from 'react-router-dom';
import { ImgStyled } from '../../../styles/ImgStyled';
import styled from 'styled-components';

const ItemBanner = ({ isLogin, bannerName }) => {
  const { action } = useContext(ModalContext);
  const mobileSize = 900;
  const [isMobile, setIsMobile] = useState(window.innerWidth <= mobileSize);
  const navigate = useNavigate();

  const imgUrl = [
    {
      creditBanner: {
        pc: 'https://fab-assets-v2.s3.ap-northeast-2.amazonaws.com/img/banner/credit_banner_pc.png',
        mo: 'https://fab-assets-v2.s3.ap-northeast-2.amazonaws.com/img/banner/credit_banner_mo.png',
      },
    },
    {
      freeItemBanner: {
        // signUpPc:
        //   'https://fab-assets-v2.s3.ap-northeast-2.amazonaws.com/img/banner/signup_banner_pc.png',
        // signUpMo:
        //   'https://fab-assets-v2.s3.ap-northeast-2.amazonaws.com/img/banner/signup_banner_mo.png',
        invitePc:
          'https://fab-assets-v2.s3.ap-northeast-2.amazonaws.com/img/banner/invite_banner_pc.png',
        inviteMo:
          'https://fab-assets-v2.s3.ap-northeast-2.amazonaws.com/img/banner/invite_banner_mo.png',
      },
    },
    {
      cardBanner: {
        pc: 'https://fab-assets-v2.s3.ap-northeast-2.amazonaws.com/img/point/credits.jpg',
        mo: 'https://fab-assets-v2.s3.ap-northeast-2.amazonaws.com/img/point/credits.jpg',
      },
    },
  ];
  const creditBanner = bannerName === 'creditBanner' && imgUrl[0].creditBanner;
  const freeItemBanner = bannerName === 'freeItemBanner' && imgUrl[1].freeItemBanner;
  const cardBanner = bannerName === 'cardBanner' && imgUrl[2].cardBanner;

  const clickMyPointBanner = () => {
    if (!isLogin) {
      action.setIsLogin(true);
    } else {
      navigate(MY_CREDIT);
    }
  };

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= mobileSize);
    };

    window.addEventListener('resize', handleResize);

    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return (
    <>
      {creditBanner && (
        <CreditBanner>
          <div onClick={clickMyPointBanner} className={isLogin ? '' : 'need-login'}>
            <ImgStyled src={isMobile ? creditBanner.mo : creditBanner.pc} alt='' />
          </div>
        </CreditBanner>
      )}

      {freeItemBanner && (
        <>
          <FreeItemBanner isMobile={isMobile}>
            <button onClick={() => action.setIsSignup(true)}>
              <ImgStyled
                src={isMobile ? freeItemBanner.signUpMo : freeItemBanner.signUpPc}
                alt=''
              />
            </button>
            <button onClick={() => (!isLogin ? action.setIsSignup(true) : navigate('/invite'))}>
              <ImgStyled
                src={isMobile ? freeItemBanner.inviteMo : freeItemBanner.invitePc}
                alt=''
              />
            </button>
          </FreeItemBanner>
        </>
      )}

      {cardBanner && (
        <CardBanner>
          <ImgStyled src={cardBanner.pc} alt='' />
        </CardBanner>
      )}
    </>
  );
};

export default ItemBanner;

const CreditBanner = styled.div`
  cursor: pointer;
  margin: 0 0 18px 0;

  @media (max-width: 900px) {
    margin: 0 0 13px 0;
  }

  img {
    width: 100%;
  }
`;

const FreeItemBanner = styled.div`
  cursor: pointer;
  margin: 0 0 18px 0;

  @media (max-width: 900px) {
    margin: 0 0 13px 0;
  }

  img {
    width: 100%;
  }

  button {
    background: none;
    border: none;
    padding: 0;
    cursor: pointer;
  }
`;

const CardBanner = styled.div`
  margin: 20px 0;

  img {
    width: 100%;
  }
`;
