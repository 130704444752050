import { useContext, useEffect, useState, useRef, useCallback } from 'react';
import UserContext from '../../../context/user/user';
import DesignerSectionDropDown from './DesignerSectionDropDown';
import styled from 'styled-components';
import DesignerSectionContent from './DesignerSectionContent';
import { getDesignerList } from '../../../action/request';
import { useRecoilState } from 'recoil';
import {
  preDesignerListAtom,
  sortStateAtom,
  designerListAtom,
  randomSortStateAtom,
  idsAtom,
} from '../../../atom/atom';

import { isMobile } from 'react-device-detect';

const DesignerSection = ({ maxWidth }) => {
  const filterDefault = isMobile ? 'Filter' : 'Random';
  const sortDefault = isMobile ? 'Sort' : 'Default';
  const isLogin = localStorage.getItem('accessToken');

  const { state: userData } = useContext(UserContext);
  const [isLoading, setIsLoading] = useState(true); // 로딩 상태 추가
  const observer = useRef();
  const [sortState, setSortState] = useRecoilState(sortStateAtom);
  const [total, setTotal] = useState(sortState ? sortState?.total : 0);
  const [page, setPage] = useState(sortState ? sortState?.offset : 'page');
  const [randomValue, setRandomValue] = useRecoilState(randomSortStateAtom);
  const [designerList, setDesignerList] = useRecoilState(designerListAtom);
  const [sortType, setSortType] = useState(sortState ? sortState?.sortType : 'Random');
  const [sortDir, setSortDir] = useState(sortState ? sortState?.sortDir : 'Default');
  const [preDesignerList, setPreDesignerList] = useRecoilState(preDesignerListAtom);
  const [sIds, setsIds] = useRecoilState(idsAtom);
  const [sIdsIndex, setsIdsIndex] = useState(designerList === false ? 0 : sortState?.idsIndex);

  const handleSortType = (option, type) => {
    let sortDirValue = '';
    if (type === 'sortType') {
      if (randomValue) {
        const value = option === 'Name' ? 'Ascending' : 'Descending';
        sortDirValue = value;
        setSortDir(value);
      } else {
        const value = option === 'Name' ? 'Ascending' : 'Descending';
        setSortDir(value);
        sortDirValue = value;
      }
      setSortType(option);
      setRandomValue(false);
    } else {
      setSortDir(option);
      sortDirValue = option;
    }

    setsIdsIndex(0);
    setSortState({
      offset: 0,
      size: 5,
      sortType: type === 'sortType' ? option : sortType,
      sortDir: sortDirValue,
      total: sortState.total || total,
      idsIndex: 0,
    });

    if (type === 'sortDir' && sortDir === option) return;
    if (type === 'sortType' && sortType === option) return;
    setDesignerList(false);
    setTotal(0);
    setPage('page');
  };

  const fetchData = async () => {
    try {
      if (!preDesignerList) {
        const { data } = await getDesignerList(userData?.isLogin, {
          offset: 0,
          size: 5,
          sortType: sortType,
          sortDir: sortDir,
          ids: sIdsIndex === 0 ? '' : sIds[sIdsIndex].join(','),
        });

        const resultPagingInfo = data.data.pagingInfo;
        const sIdsArr = data.data.pagingInfo.ids;
        const idsArray = sIdsArr.split(',').map(id => id.trim());

        const chunkSize = 5;
        const chunks = [];
        for (let i = 0; i < idsArray.length; i += chunkSize) {
          chunks.push(idsArray.slice(i, i + chunkSize));
        }

        if (sIdsIndex === 0) {
          setsIds(chunks);
        }

        const resultDataList = data.data.list;

        const resultTotal = data.data.total;
        setTotal(resultTotal);
        setSortState({
          sortDir: resultPagingInfo.sortDir,
          sortType: resultPagingInfo.sortType,
          total: resultTotal,
          idsIndex: sIdsIndex,
          ...(randomValue && { randomSort: true }),
        });
        if (sIdsIndex !== 0) {
          setDesignerList([...designerList, ...resultDataList]);
        } else {
          setDesignerList(resultDataList);
        }

        setPreDesignerList(false);
      }

      setIsLoading(false);
    } catch (error) {}
  };

  const lastElementRef = useCallback(
    node => {
      if (observer.current) observer.current.disconnect();

      observer.current = new IntersectionObserver(entries => {
        setPreDesignerList(false);
        if (entries[0].isIntersecting) {
          if (sIds == '' || sIds?.length >= sIdsIndex + 1) {
            if (designerList === false) {
              setsIdsIndex(0);
            } else {
              setsIdsIndex(prevIndex => prevIndex + 1);
            }
          } else {
            return;
          }
        }

        setPreDesignerList(false);
      });

      if (node) observer.current.observe(node);
    },
    [designerList?.length]
  );

  useEffect(() => {
    if ((designerList && sIds?.length === sIdsIndex) || preDesignerList) {
      setIsLoading(false);
      return;
    } else {
      setIsLoading(false);
      fetchData();
    }
  }, [sIdsIndex, sortDir, sortType, userData?.isLogin]);

  useEffect(() => {
    if (!designerList) {
      localStorage.removeItem('DesignerPageScrollPosition');
    }
    // 컴포넌트가 마운트될 때 스크롤 위치 복원
    const savedScrollPosition = localStorage.getItem('DesignerPageScrollPosition');

    if (savedScrollPosition) {
      setTimeout(() => {
        window.scrollTo(0, parseFloat(savedScrollPosition));
      }, 500); // 1초 뒤에 스크롤 위치 복원
    }

    const handleScroll = () => {
      const currentScrollPosition = window.scrollY;
      localStorage.setItem('DesignerPageScrollPosition', currentScrollPosition);
    };

    // 스크롤 이벤트 리스너 추가
    window.addEventListener('scroll', handleScroll);

    return () => {
      // 스크롤 이벤트 리스너 제거
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  useEffect(() => {
    const handleBeforeUnload = event => {
      const progressModalY = localStorage.getItem('progressModalY');
      if (progressModalY) return;
      window.scrollTo(0, 0);
      setDesignerList(false);
      setSortState(false);
      setTotal(0);
      setRandomValue(true);
    };

    window.addEventListener('beforeunload', handleBeforeUnload);
    if (!preDesignerList) {
      window.scrollTo(0, 0);
    }

    return () => {
      setPreDesignerList(false);
      window.removeEventListener('beforeunload', handleBeforeUnload);
    };
  }, []);

  return (
    <DesignerSectionStyle maxWidth={maxWidth} id='designerSection'>
      <DesignerSectionDropDownWrapper>
        <DesignerSectionDropDown
          handleSortType={handleSortType}
          options={['Name', 'Downloads', 'Newest', 'Models']}
          designerSectionPage={true}
          value='sortType'
          sortValue={randomValue ? filterDefault : sortType}
        />
        <DesignerSectionDropDown
          handleSortType={handleSortType}
          options={['Ascending', 'Descending']}
          value='sortDir'
          sortValue={randomValue ? sortDefault : sortDir}
          typeBtn={sortType === 'Random' || randomValue}
        />
      </DesignerSectionDropDownWrapper>
      {designerList && (
        <DesignerSectionContent designerList={designerList} setDesignerList={setDesignerList} />
      )}
      {!isLoading && <div ref={lastElementRef}></div>}
    </DesignerSectionStyle>
  );
};

const DesignerSectionDropDownWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  position: relative;
  z-index: 50;
  gap: 20px;
  margin-top: 40px;

  @media screen and (max-width: 450px) {
    gap: 10px;
    margin-right: 10px;
  }
`;

export default DesignerSection;

const DesignerSectionStyle = styled.div`
  max-width: ${props => props.maxWidth};
  min-height: 500px;
  margin: 0 auto;
`;
