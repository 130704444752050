import React, { useContext, useEffect, useRef, useState } from 'react';
import { useLocation, useNavigate, Link } from 'react-router-dom';
import { ls } from '../../constant';
import Community from './Comuunity';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Pagination, FreeMode } from 'swiper/modules';
import CategoryMenu from './CategoryMenu';
import { DESIGNERS, MAIN, MY_CART, PRINT_TIPS_LIST, REQUESTS, REVIEW } from '../../route/constant';
import UserContext from '../../context/user/user';
import { getCarts, getUserInfo, logout } from '../../action/request';
import { DEFAULT_AVATAR } from '../../util/image';
import UserMenu from './UserMenu';
import Search2 from './Search2';
import { allowScroll, preventScroll } from '../../util/commentImageViewer';
import CartContext from '../../context/cart.js/carts';
import { Sticky } from 'react-sticky';
import { ImgStyled } from '../../styles/ImgStyled';
import { useRecoilState, useRecoilValue } from 'recoil';
import styled from 'styled-components';

import {
  paginationAtom,
  selectedTabAtom,
  preDesignerListAtom,
  randomSortStateAtom,
  designerListAtom,
  sortStateAtom,
  itemDetailNavAtom,
  idsAtom,
} from '../../atom/atom';
import { SUPPLIES_URL } from '../common/constants';
import { useCookies } from 'react-cookie';

function Logined2({
  searchKeyword,
  onChangeSearchKeyword,
  resetSearchKeyword,
  isMobile,
  showMenu = true,
  isPhone,
}) {
  const [isOpenCategoryMenu, setIsOpenCategoryMenu] = useState(false);
  const [cookies, setCookie] = useCookies(['autoLogin']);
  const [isOpenCategory, setIsOpenCategory] = useState(true);
  const [isCommunity, setIsCommunity] = useState(false);
  const [isMy, setIsMy] = useState(false);
  const { state: userState, action: userAction } = useContext(UserContext);
  const { state: cartsState, action: cartsAction } = useContext(CartContext);
  const [gnbMouseHover, setGnbMouseHover] = useState(false);
  const [, setSelectedTab] = useRecoilState(selectedTabAtom);
  const [, setPageNation] = useRecoilState(paginationAtom);
  const [, setPreDesignerList] = useRecoilState(preDesignerListAtom);
  const [, setRandomValue] = useRecoilState(randomSortStateAtom);
  const [, setDesignerList] = useRecoilState(designerListAtom);
  const [, setSortState] = useRecoilState(sortStateAtom);
  const [, setsIds] = useRecoilState(idsAtom);
  const itemDetailNav = useRecoilValue(itemDetailNavAtom);
  const scrollY = window.scrollY === 0;
  const [isProfileImageLoaded, setIsProfileImageLoaded] = useState(false);
  const isMobile2 = window.innerWidth < 700;

  // const [isLoginAtom, setIsLoginAtom] = useRecoilState(loginAtom);
  const navigate = useNavigate();
  const location = useLocation();
  const pathname = useLocation().pathname;

  const isLandingPage = location.pathname.includes('/landing');
  const isMyPage = location.pathname.split('/')[1] === 'my';
  const isCheckoutPage = location.pathname.includes('checkout');
  const row2Nav =
    showMenu || gnbMouseHover
      ? !isMobile
        ? 'translateY(49px)'
        : 'translateY(74px)'
      : 'translateY(0px)';

  const TOP_BANNER_HEIGHT = 45;

  const options = {
    slidesPerView: 'auto',

    centeredSlides: false,
    spaceBetween: 0,
    loop: false,
  };

  useEffect(() => {
    localStorage.setItem('OpenCategoryMenu', false);
  }, []);

  function handleImageLoad() {
    setIsProfileImageLoaded(true);
  }

  const goTo = (e, path) => {
    // 페이지네이션, sort, type filter 값 초기화
    localStorage.removeItem(ls.itemPage);
    localStorage.removeItem(ls.itemSort);
    localStorage.removeItem(ls.itemType);

    e.preventDefault();
    e.stopPropagation();

    // 홈으로 이동 시 이전에 홈에서 unmount될 때 저장된 이전 스크롤 위치는 지운다.
    if (path === MAIN) localStorage.removeItem(ls.prevScrollY);
    if (userState?.isDesigner) {
      navigate(path, { state: { id: userState?.idStudio } });
      setSelectedTab(0);
    } else {
      navigate(path);
    }

    setIsOpenCategoryMenu(false);
    setIsOpenCategory(true);
    setIsCommunity(false);
    setIsMy(false);
    setPageNation(1);

    if (path !== '/designers') {
      setDesignerList(false);
      setPreDesignerList(false);
      setRandomValue(true);
      setSortState(false);
      setsIds('');
    }

    localStorage.setItem('scroll', 0);

    if (document.body.style.position === 'fixed') allowScroll();
  };

  const toggleCategoryMenu = () => {
    setIsCommunity(false);
    setIsOpenCategoryMenu(prev => !prev);
    localStorage.setItem('OpenCategoryMenu', !isOpenCategoryMenu);
  };

  // 카테고리 탭 노출 유무에 따른 scroll 기능을 활성화/비활성화 한다.
  useEffect(() => {
    if (!isOpenCategoryMenu) allowScroll();
    else preventScroll();
  }, [isOpenCategoryMenu]);

  const toggleCategory = () => {
    setIsOpenCategory(prev => !prev);
  };

  const openCommunityTab = () => {
    setIsCommunity(prev => !prev);
  };
  const openMyMenu = () => {
    setIsMy(prev => !prev);
  };

  const onLogout = async e => {
    e.preventDefault();
    e.stopPropagation();

    try {
      await logout(localStorage.getItem(ls.refreshToken));
      setDesignerList(false);
      setPreDesignerList(false);
      setSortState(false);
      setsIds('');
      setRandomValue(false);
      userAction.setInit();
      localStorage.removeItem(ls.accessToken);
      localStorage.removeItem(ls.refreshToken);
      setCookie('autoLogin', '', { path: '/', maxAge: 0 });

      // 로그아웃 시 마이페이지 or 체크아웃 페이지일 경우 홈으로 이동
      if (isMyPage || isCheckoutPage) navigate('/');
    } catch (e) {}
  };

  const communityRef = useRef(null);
  const myRef = useRef(null);

  useEffect(() => {
    const handleClickOutside = event => {
      if (isCommunity && !communityRef?.current?.contains(event.target)) {
        setIsCommunity(false);
      }
      if (isMy && !myRef?.current?.contains(event.target)) {
        setIsMy(false);
      }
    };
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [isCommunity, isMy]);

  const fetchCartData = async () => {
    try {
      const { data: resCarts } = await getCarts();
      cartsAction.changeCartsCount(resCarts.data?.checkoutDto?.products?.length || 0);
      localStorage.setItem(ls.cartsCount, resCarts.data?.checkoutDto?.products?.length || 0);
    } catch (e) {}
  };

  useEffect(() => {
    if (userState.isLogin) fetchCartData();
  }, [cartsState.cartsCount, userState.isLogin, pathname]);

  return (
    // itemDetailNav
    // <!-- Header - start -->
    <Header islanding={isLandingPage} itemDetailNav={itemDetailNav}>
      <Sticky topOffset={!isPhone ? TOP_BANNER_HEIGHT : 0}>
        {({ style }) => (
          <div
            style={style}
            className='sticky-container'
            onMouseEnter={() => {
              setGnbMouseHover(true);
            }}
            onMouseLeave={() => {
              setGnbMouseHover(false);
            }}
          >
            <div className='row1'>
              <Link to='#' className='headerLogo' title='Home' onClick={e => goTo(e, MAIN)}>
                <ImgStyled src='/assets/img/2.0WebImg/ui/fab365.svg' alt='Fab365' />
              </Link>

              <Search2
                searchKeyword={searchKeyword}
                onChangeSearchKeyword={onChangeSearchKeyword}
                resetSearchKeyword={resetSearchKeyword}
              />

              <div className='subMenu'>
                {/* <Link className='gotoSupplies' to={'/apply-designer'} style={{ marginRight: 10 }}>
                  ApplyForm
                </Link> */}
                <Link className='gotoSupplies' to={SUPPLIES_URL} target='_blank' rel='noreferrer'>
                  Supplies
                </Link>
                <Link to='#' className='gotoCart' onClick={e => goTo(e, MY_CART)}>
                  <span className='badge'>{localStorage.getItem(ls.cartsCount)}</span>
                  Cart
                </Link>
                <button tabIndex='0' type='button' className='myPic' onClick={openMyMenu}>
                  <img
                    src={userState.thumbnail || DEFAULT_AVATAR}
                    className='myPicImg1'
                    alt=''
                    onLoad={handleImageLoad}
                  />
                  {!isProfileImageLoaded && !isMobile2 && <div className='myPicLoading'></div>}
                </button>
              </div>

              {/* 모바일 버전에서 활성화 */}
              {isMobile && (
                <button
                  tabIndex='0'
                  type='button'
                  className='buttonCategory'
                  onClick={toggleCategoryMenu}
                >
                  <div className='iconMenu'></div>
                  <div className='txt1'>Category</div>
                  <div className='iconArrow'></div>
                </button>
              )}
            </div>
            <div
              className='row2'
              style={{
                transform:
                  showMenu || gnbMouseHover
                    ? !isMobile
                      ? 'translateY(49px)'
                      : 'translateY(74px)'
                    : 'translateY(0px)',
              }}
            >
              <div className='row2-inner'>
                {/* 데스크탑에서 활성화 */}
                {!isMobile && (
                  <button
                    tabIndex='0'
                    type='button'
                    className='buttonCategory'
                    onClick={toggleCategoryMenu}
                  >
                    <div className='iconMenu'></div>
                    <div className='txt1'>Category</div>
                    <div className='iconArrow'></div>
                  </button>
                )}

                <nav>
                  <Swiper
                    className='navSwiper'
                    {...options}
                    modules={[Pagination, FreeMode]}
                    freeMode
                  >
                    <div className='swiper-wrapper'>
                      <SwiperSlide className='swiper-slide'>
                        {/* <!-- 현재 페이지를 가리키는 요소에 active 클래스를 넣습니다. --> */}
                        <Link
                          to='#'
                          className={`navMenu ${location.pathname === MAIN && 'active'}`}
                          onClick={e => goTo(e, MAIN)}
                        >
                          <span>HOME</span>
                        </Link>
                      </SwiperSlide>

                      <SwiperSlide className='swiper-slide'>
                        {/* TODO:디자이너url변경 */}
                        <Link
                          to='#'
                          className={`navMenu ${location.pathname.includes(DESIGNERS) && 'active'}`}
                          onClick={e => goTo(e, DESIGNERS)}
                        >
                          <span>Designers</span>
                        </Link>
                      </SwiperSlide>

                      <SwiperSlide className='swiper-slide'>
                        <Link
                          to='#'
                          className={`navMenu ${
                            location?.search?.includes('reward_model') && 'active'
                          }`}
                          onClick={e => goTo(e, '/search?tag=reward_model')}
                        >
                          <span>Rewards</span>
                        </Link>
                      </SwiperSlide>

                      <SwiperSlide className='swiper-slide'>
                        <a
                          to='#'
                          className={`navMenu ${
                            (location.pathname.includes(REVIEW) && 'active') ||
                            (location.pathname.includes(REQUESTS) && 'active') ||
                            (location.pathname.includes(PRINT_TIPS_LIST) && 'active') ||
                            (location.pathname.includes('help') && 'active') ||
                            (location.pathname.includes('pages') && 'active')
                          }`}
                          onClick={openCommunityTab}
                        >
                          <span>Community</span>
                        </a>
                      </SwiperSlide>

                      {/* TODO: 1차 개발에서 제외 */}
                      {/* <SwiperSlide className='swiper-slide'>
                      <a href='' className={`navMenu ${location.pathname.includes(HELP) && 'active'}`}>
                        <span>Help</span>
                      </a>
                    </SwiperSlide> */}
                    </div>
                  </Swiper>
                </nav>
              </div>
            </div>

            <div className='centerWrap'>
              {isCommunity && (
                <Community
                  goTo={goTo}
                  communityRef={communityRef}
                  style={{
                    transform: showMenu
                      ? !isMobile
                        ? 'translateY(60px)'
                        : 'translateY(93px)'
                      : 'translateY(-600px)',
                  }}
                />
              )}
              <CategoryMenu
                isOpenCategoryMenu={isOpenCategoryMenu}
                isOpenCategory={isOpenCategory}
                toggleCategoryMenu={toggleCategoryMenu}
                toggleCategory={toggleCategory}
                goTo={goTo}
                onLogout={onLogout}
              />
              {isMy && <UserMenu goTo={goTo} onLogout={onLogout} myRef={myRef} />}
            </div>
          </div>
        )}
      </Sticky>
    </Header>
  );
}

export default Logined2;

export const Header = styled.header`
  display: ${props => (props.islanding ? 'none' : '')};

  .sticky-container {
    ${props =>
      props.itemDetailNav
        ? 'transform: translateY(-145px) !important'
        : 'transform: translateY(0px) !important'};
    ${props =>
      props.itemDetailNav
        ? 'transition: transform 0.3s ease-in-out'
        : 'transition: transform 0.3s ease-in-out'};
  }
`;
