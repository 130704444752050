import { useEffect, useRef, useState } from 'react';
import {
  OPTION_ITEM_NEW,
  OPTION_ITEM_POPULAR,
  OPTION_ITEM_PRICE_HIGH,
  OPTION_ITEM_PRICE_LOW,
} from './constant';
import { Dropdown, DropdownItem, DropdownMenu, DropdownToggle } from './SelectType';

const SelectSort = ({ value, onchange, renderComponent }) => {
  const [isOpen, setIsOpen] = useState(false);
  const dropdownRef = useRef(null);

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  const select = e => {
    onchange(e.target.dataset.value);
    setIsOpen(false);
  };

  const handleClickOutside = event => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      setIsOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  return (
    // <>
    //   <select className='form-input sort' onChange={select} value={value}>
    //     <option value={OPTION_ITEM_NEW}>Newest</option>
    //     <option value={OPTION_ITEM_POPULAR}>Popular</option>
    //     <option value={OPTION_ITEM_PRICE_HIGH}>High Price</option>
    //     <option value={OPTION_ITEM_PRICE_LOW}>Low Price</option>
    //   </select>
    // </>
    <Dropdown ref={dropdownRef}>
      <DropdownToggle onClick={toggleDropdown}>
        {value === OPTION_ITEM_NEW
          ? 'Newest'
          : value === OPTION_ITEM_POPULAR
          ? 'Popular'
          : value === OPTION_ITEM_PRICE_HIGH
          ? 'High Price'
          : 'Low Price'}
        <img src='/assets/img/designers/icon_down.png' alt='' />
      </DropdownToggle>
      {isOpen && (
        <DropdownMenu renderComponent={renderComponent}>
          <DropdownItem
            data-value={OPTION_ITEM_NEW}
            onClick={select}
            className={value === OPTION_ITEM_POPULAR ? 'selected' : ''}
          >
            Newest
          </DropdownItem>
          <DropdownItem
            data-value={OPTION_ITEM_POPULAR}
            onClick={select}
            className={value === OPTION_ITEM_POPULAR ? 'selected' : ''}
          >
            Popular
          </DropdownItem>

          <DropdownItem
            data-value={OPTION_ITEM_PRICE_HIGH}
            onClick={select}
            className={value === OPTION_ITEM_POPULAR ? 'selected' : ''}
          >
            High Price
          </DropdownItem>
          <DropdownItem
            data-value={OPTION_ITEM_PRICE_LOW}
            onClick={select}
            className={value === OPTION_ITEM_PRICE_LOW ? 'selected' : ''}
          >
            Low Price
          </DropdownItem>
        </DropdownMenu>
      )}
    </Dropdown>
  );
};

export default SelectSort;
