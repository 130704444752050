/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useContext, useEffect, useState } from 'react';
import Layout from '../../../component/layout/Layout';
import { useNavigate, useParams } from 'react-router-dom';
import {
  createCartAdd,
  getCategories,
  getCollectionItemsNewV2,
  getFreeLimit,
  toggleItemLike,
} from '../../../action/request';
import { gtagAddToCart, gtagViewItemList } from '../../../action/gTag';
import { COLLECTIONS } from '../../../route/constant';
import { ls } from '../../../constant';
import styled from 'styled-components';
import ModelCard from '../../../component/common/itemCards/ModelCard';
import { Webpage2StylesContainer } from '../../../styles/Webpage2Styles';
import useClickHandler from '../../../component/common/itemCards/useCardClickHandeler';
import useDownload from '../../../hook/useDownload';
import Selectefile from '../../../component/selectefile/Selectefile';
import { BannerWrapper, CollectionGrid } from './CollectionGridStyle';
import Pagenation from '../../../component/designers/Pagenation';
import UserContext from '../../../context/user/user';
import ModalContext from '../../../context/modal/modal';
import { likeAtom } from '../../../atom/atom';
import { useRecoilState } from 'recoil';
import { updateLikeState } from '../../../util/updateLikeState';
import CartContext from '../../../context/cart.js/carts';
import GoogleAds from '../../../component/adv/GoogleAds';

const CollectionItemList = () => {
  const { oneClick, twoClick, handleClick } = useClickHandler();
  const [likeState, setLikeState] = useRecoilState(likeAtom);

  const { state: cartsState, action: cartsAction } = useContext(CartContext);
  const {
    selectedId,
    selectedName,
    selectedModal,
    onClickDownload,
    setSelectedModal,
    setSelectedId,
  } = useDownload();
  const [categories, setCategories] = useState([]);
  const [page, setPage] = useState(Number(localStorage.getItem(ls.collectionListPage)) || 1);
  const [collectionItems, setCollectionItems] = useState({ count: 0 });
  const [categoryTitle, setCategoryTitle] = useState('');
  const [limit, setLimit] = useState({});
  const { collectionURL } = useParams();
  const { state: userState } = useContext(UserContext);
  const { action: modalAction } = useContext(ModalContext);
  const navigate = useNavigate();
  const mobile = 770;
  const [isMobile, setIsMobile] = useState(window.innerWidth < mobile);

  useEffect(() => {
    fetchData();
  }, [userState?.isLogin]);

  const fetchData = async () => {
    try {
      const { data: categories } = await getCategories();
      setCategories(categories.data);

      const { data: resLimit } = await getFreeLimit();
      setLimit(resLimit.data);

      updateItemPage(page);
      // gtag 아이템 리스트 조회 태그
      if (collectionURL) {
        gtagViewItemList(collectionURL);
        // console.log('tag test', window.location.href, collectionName);
      }
    } catch (error) {}
  };

  const pageChange = async value => {
    updateItemPage(value);
  };

  const pageConfirm = value => {
    updateItemPage(value);
  };

  const updateItemPage = async value => {
    setPage(value);
    localStorage.setItem(ls.collectionListPage, value);

    try {
      const { data: resCollectionItems } = await getCollectionItemsNewV2({
        page: value,
        url: collectionURL,
      });
      // console.log(resCollectionItems.data);
      if (resCollectionItems.returnCode === 'C00000') {
        setCollectionItems(resCollectionItems.data);
        setCategoryTitle(resCollectionItems.data.category_name);
      } else if (resCollectionItems.returnCode === 'C00009') {
        // url로 조회한 아이템이 없다면 > 없는 페이지이므로 ERROR 페이지로 이동
        navigate(COLLECTIONS);
      }
    } catch (error) {}
  };

  const goToCollections = e => {
    e.preventDefault();
    e.stopPropagation();
    navigate(COLLECTIONS);
  };

  const moveScroll = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  const clickLike = async (e, id, count) => {
    e.stopPropagation();
    if (!userState?.isLogin) {
      modalAction.setIsLogin(true);
    } else {
      const { data } = await toggleItemLike({ idItem: id });
      if (data) {
        const updatedCards = await updateLikeState({
          id: id,
          setLikeState: setLikeState,
          cards: collectionItems.items,
          count: count,
          data: data,
        });
        setCollectionItems({ ...collectionItems, items: updatedCards });
      }
    }
  };

  const clickCart = async (e, price, id, inCart) => {
    // 이미 카트에 담겨있을 경우
    e.stopPropagation();
    if (inCart) return;

    if (!userState.isLogin) {
      // 로그인이 안되어 있을 때 로그인 모달을 보여준다.
      modalAction.setIsLogin(true);
    } else {
      try {
        const { data: res } = await createCartAdd(id);
        if (res) {
          // 기존 카트 갯수에 +1 을 한다.
          cartsAction.changeCartsCount(cartsState.cartsCount + 1);
          localStorage.setItem(ls.cartsCount, cartsState.cartsCount + 1);

          alert(res.result);

          // 기존 카드 상태에 카트 값 업데이트
          const newCards = collectionItems.items.map(card =>
            card.product_id === id
              ? {
                  ...card,
                  in_cart: !card.in_cart,
                }
              : card
          );
          setCollectionItems({ ...collectionItems, items: newCards });
          // gtag 장바구니 추가 태그
          gtagAddToCart({ value: Number(price), itemId: id });
        }
      } catch (e) {}
    }
  };

  useEffect(() => {
    window.addEventListener('resize', () => {
      setIsMobile(window.innerWidth < mobile);
    });
    return () => {
      window.removeEventListener('resize', () => {
        setIsMobile(window.innerWidth < mobile);
      });
    };
  }, []);

  return (
    <>
      <Layout>
        <Webpage2StylesContainer>
          <CollectionItemListStyled id='main' isMobile={isMobile}>
            <div className='page-top page-top-with-breadcrumb'>
              <div className='container container-lg container'>
                <h1 className='page-title02'>COLLECTION</h1>
                <h2 className='page-title-sub'>{categoryTitle}</h2>
              </div>
            </div>

            <div className='main-content-with-aside'>
              {/* <Categoryist current={'Collections'} data={categories.items} /> */}
              <div className='main-content'>
                {/* collection 아이템 조회 */}
                <section className='store-collection-list'>
                  <div className='container'>
                    <div
                      className='content-section content-section-has-top-nav'
                      style={{ minHeight: '900px' }}
                    >
                      <div className='product-list product-list-3'>
                        <BannerWrapper margin={'0px 0px 0px 0px'}>
                          <GoogleAds type='typeA' height={92} renderComponent={'category'} />
                        </BannerWrapper>
                        <BackButton onClick={goToCollections}>
                          {'<'} Back to the collection list
                        </BackButton>
                        <CollectionGrid>
                          {collectionItems?.items?.length > 0
                            ? collectionItems?.items?.map((item, idx) => {
                                const isBanner = idx === 8 || idx === 16;
                                return (
                                  <>
                                    {isBanner && (
                                      <BannerWrapper>
                                        <GoogleAds
                                          type='typeA'
                                          height={92}
                                          renderComponent={'category'}
                                        />
                                      </BannerWrapper>
                                    )}
                                    <ModelCard
                                      key={idx}
                                      handleClick={() => handleClick(idx)}
                                      twoClick={twoClick === idx}
                                      oneClick={oneClick === idx}
                                      data={item}
                                      clickCart={clickCart}
                                      clickLike={clickLike}
                                      onClickDownload={onClickDownload}
                                      buyStatus={item.buy_status}
                                      downloadStatus={item.download_status}
                                      isDesigner={item.isDesigner && userState.isLogin}
                                      renderComponent={'collectionItemList'}
                                    />
                                  </>
                                );
                              })
                            : Array.from({ length: 24 }).map((_, idx) => (
                                <ModelCard loading={true} key={idx} />
                              ))}
                        </CollectionGrid>
                      </div>

                      {collectionItems.count > 0 && (
                        <Pagenation
                          count={collectionItems.count}
                          setPage={setPage}
                          page={page}
                          moveScroll={moveScroll}
                          setPageNation={pageChange}
                          viewCount={24}
                          value={'collectionList'}
                        />
                      )}
                    </div>
                  </div>
                </section>
              </div>
            </div>
          </CollectionItemListStyled>
          {selectedId && selectedModal && (
            <Selectefile
              setSelectedModal={setSelectedModal}
              id={selectedId}
              setSelectedId={setSelectedId}
              selectedName={selectedName}
              status={true}
            />
          )}
        </Webpage2StylesContainer>
      </Layout>
    </>
  );
};

export default CollectionItemList;

const BackButton = styled.button`
  font-family: Montserrat;
  font-size: 18px;
  font-weight: 500 !important;
  line-height: 21.94px;
  text-align: left;
  color: #000;
  background-color: transparent;
  border: none;
  padding: 0 0.75rem;
  cursor: pointer;
  margin-bottom: 16px !important;
  margin-top: 30px !important;
  color: #000 !important;
  font-weight: 500 !important;
  font-size: 18px !important;

  @media screen and (max-width: 700px) {
    position: relative !important;
    right: 14px !important;
  }
`;

export const CollectionItemListStyled = styled.div`
  .content-section {
    padding-top: 10px;
  }

  @media screen and (max-width: 830px) {
    .page-top.page-top-lg,
    .page-top.page-top-with-breadcrumb {
      height: 120px;
    }

    .page-title-sub {
      font-size: 24px;
      font-weight: 600;
      text-align: center;
    }

    .page-title02 {
      font-size: 14px !important;
    }

    .page-top .container-lg.container {
      gap: 5px;
    }

    .page-title {
      font-size: 24px !important;
    }
  }

  @media screen and (max-width: 700px) {
    margin-top: 20px !important;
  }
`;
