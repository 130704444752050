/* eslint-disable jsx-a11y/anchor-is-valid */
import { Link, useNavigate } from 'react-router-dom';
import { COLLECTIONS } from '../../../route/constant';
import { ImgStyled } from '../../../styles/ImgStyled';
import styled from 'styled-components';
import { AdBoxMobile } from '../../GoogleAds/CollectionAds';
import GoogleAds from '../../../component/adv/GoogleAds';
import { useEffect, useState } from 'react';
const CollectionList = ({ data }) => {
  const navigate = useNavigate();
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 1280);

  useEffect(() => {
    window.addEventListener('resize', () => {
      setIsMobile(window.innerWidth <= 1280);
    });

    return () => {
      window.removeEventListener('resize', () => {});
    };
  }, []);

  return (
    <>
      {data?.map((c, idx) => {
        const color = idx % 2 !== 0 ? 'light' : 'dark';
        const btn = idx % 2 !== 0 ? '' : 'btn-white';

        const isBanner = idx === 0 || idx === 1 || idx === 2 || idx === 3;
        return (
          <>
            <CollectionItem className={'featured-collection ' + color} key={c.id_category}>
              <div className='img'>
                <ImgStyled src={c.img} alt={c.name} />
              </div>
              <div className='desc'>
                <div className='desc-inner'>
                  <h3>{c.name}</h3>
                  <p>{c.desc}</p>
                  <Link
                    to={COLLECTIONS + '/' + c.url}
                    className={'btn-all btn btn-hover ' + btn}
                    onClick={e => {
                      e.preventDefault();
                      e.stopPropagation();
                      navigate(COLLECTIONS + '/' + c.url);
                    }}
                  >
                    View All
                  </Link>
                </div>
              </div>
            </CollectionItem>
            {isBanner && isMobile && (
              <GoogleAds
                type='collectionList'
                marginBottom={34}
                renderComponent={'collectionList'}
              />
            )}
          </>
        );
      })}
    </>
  );
};

export default CollectionList;

const CollectionItem = styled.div`
  .btn-all {
    width: 129px !important;
    font-weight: 500 !important;
  }

  @media screen and (max-width: 830px) {
    .btn-all {
      width: 85px !important;
      font-size: 14px !important;
      padding: 0 !important;
    }
  }
`;
