import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { Autoplay, Navigation } from 'swiper/modules';
import { Swiper, SwiperSlide } from 'swiper/react';
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';
import { theme } from '../../styles/theme';
import { ImgStyled } from '../../styles/ImgStyled';
import { Link } from 'react-router-dom';
import { emptyCard } from './constant';
import { replicateObject } from '../../util/skeleton';

function Influencers({ isMobile, data }) {
  //기존 static data
  const SKELETON_PADDINGTOP = '24%'; /* 이미지의 가로-세로 비율에 따라 조정 (세로 / 가로 * 100) */
  const [isSkeletonLoading, setIsSkeletonLoading] = useState(true);
  const emptyCards = replicateObject(emptyCard, 4);
  const [cards, setCards] = useState(emptyCards);
  const options = {
    slidesPerView: 1,
    centeredSlides: false,
    spaceBetween: 20,
    loop: true,

    autoplay: {
      delay: 0,
      pauseOnMouseEnter: true,
      disableOnInteraction: false,
    },

    speed: 5000,

    // observer: true,
    // observeParents: true,

    navigation: {
      prevEl: '.buttonSwiperPrevSLIn1',
      nextEl: '.buttonSwiperNextSLIn2',
    },
    breakpoints: {
      440: {
        slidesPerView: 2,
      },
      700: {
        slidesPerView: 3,
      },
      900: {
        slidesPerView: 4,
      },
      1180: {
        slidesPerView: 4,
      },
    },
  };

  const goTo = (e, path) => {
    e.preventDefault();
    e.stopPropagation();

    window.open(path, '_blank');
  };

  // console.log(data);

  // swiper는 슬라이드 목록이 없을 때 autoplay, navigation 동작을 시작하지 않는 오류가 생기기 때문에 slide 값이 있을 때 동작하게 한다.
  useEffect(() => {
    if (data) {
      setCards(data);
      setIsSkeletonLoading(false);
    }
  }, [data]);

  if (!cards?.length) return null;

  return (
    <Container>
      <div className='centerWrap'>
        {isSkeletonLoading ? (
          <>
            <Skeleton
              style={{
                width: 140,
                height: 40,
                borderRadius: isMobile ? 0 : '8px',
                marginBottom: 30,
              }}
              baseColor={theme.skeleton_dark_gray}
              highlightColor={theme.sleleton_dark_gray_highlight}
            />
            <Skeleton
              style={{
                paddingTop: SKELETON_PADDINGTOP,
                height: 0,
                borderRadius: isMobile ? 0 : '8px',
              }}
              baseColor={theme.skeleton_dark_gray}
              highlightColor={theme.sleleton_dark_gray_highlight}
            />
          </>
        ) : (
          <div style={{ width: '100%', aspectRatio: '8/1' }}>
            <div className='titleRow'>
              <div className='title'>Videos</div>
            </div>
            <div>
              <div className='influencerList'>
                <Swiper
                  className='influencerListBody'
                  {...options}
                  modules={[Navigation, Autoplay]}
                >
                  <div className='swiper-wrapper'>
                    {data?.map(influencer => (
                      <SwiperSlide key={influencer?.id_video}>
                        <Link
                          className='swiper-slide'
                          onClick={e => goTo(e, influencer.url)}
                          to={influencer.url}
                        >
                          <ImgStyled src={influencer.thumbnail} className='influencerImg ' alt='' />
                          <div className='influencer--title'>{influencer.text}</div>
                          <div className='influencer--channel'>
                            {influencer.channel === 'NULL' ? '' : influencer.channel}
                          </div>
                        </Link>
                      </SwiperSlide>
                    ))}
                  </div>
                </Swiper>
                <button
                  tabIndex='0'
                  type='button'
                  className='buttonSwiperPrev buttonSwiperPrevSLIn1'
                  title='prev'
                  style={{ opacity: 0 }}
                ></button>
                <button
                  tabIndex='0'
                  type='button'
                  className='buttonSwiperNext buttonSwiperNextSLIn2'
                  title='next'
                  style={{ opacity: 0 }}
                ></button>
              </div>
            </div>
          </div>
        )}
      </div>
    </Container>
  );
}

export default Influencers;

const Container = styled.div`
  display: block;
  width: 100%;
  padding: 0 10px;

  .centerWrap {
    padding: 80px 0 70px;
    border-bottom: 1px solid #d8d8d8;
  }

  .influencerList {
    display: block;
    width: 100%;
    position: relative;
    margin: 30px auto 60px;
  }

  .influencerListBody {
    display: block;
    width: 100%;
    overflow: hidden !important;
  }

  .buttonSwiperPrev {
    position: absolute;
    z-index: 10;
    width: 48px;
    height: 48px;
    top: 50%;
    transform: translateY(-50%);
    left: -68px;
    background: url('/assets/img/2.0WebImg/ui/list_arrow_left.svg') center no-repeat;
    background-size: 48px 48px;
  }

  .buttonSwiperPrev:hover {
    background: url('/assets/img/2.0WebImg/ui/list_arrow_left_hover.svg') center no-repeat;
    background-size: 48px 48px;
  }

  .buttonSwiperNext {
    position: absolute;
    z-index: 10;
    width: 48px;
    height: 48px;
    top: 50%;
    transform: translateY(-50%);
    right: -68px;
    background: url('/assets/img/2.0WebImg/ui/list_arrow_right.svg') center no-repeat;
    background-size: 48px 48px;
  }

  .buttonSwiperNext:hover {
    background: url('/assets/img/2.0WebImg/ui/list_arrow_right_hover.svg') center no-repeat;
    background-size: 48px 48px;
  }

  .influencerImg {
    display: block;
    width: 100%;
    border-radius: 6px;
    cursor: pointer;
  }

  .swiper-wrapper {
    transition-timing-function: linear;
  }

  .influencer--title {
    padding: 0 0.5rem;
    margin-top: 10px;
    line-height: 25px;
    font-weight: 500;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    font-size: 18px;
    color: rgb(23, 23, 23) !important;
  }

  .influencer--channel {
    padding: 0 0.5rem;
    margin-top: 10px;
    font-size: 14px;
    color: rgb(104, 104, 104) !important;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
  }

  @media screen and (max-width: 1500px) {
    .buttonSwiperPrev {
      display: none;
    }
    .buttonSwiperNext {
      display: none;
    }
  }

  @media screen and (max-width: 700px) {
    padding: 0;

    .title {
      padding-left: 32px;
    }
  }
`;
