function calculateDiscountedPrice(originalPrice, discountRate) {
  if (originalPrice === 0 && discountRate === 0) {
    return '0.00';
  }

  if (originalPrice || discountRate) {
    var subTotal = (originalPrice * discountRate) / 100;
    subTotal = Math.floor(subTotal * 100) / 100;
    var finalPrice = Number(originalPrice) - subTotal;

    finalPrice = finalPrice.toString();

    const decimalIndex = finalPrice.indexOf('.');

    if (decimalIndex === -1) {
      finalPrice += '.00';
    } else {
      // 소수점이 있을 경우
      const decimalPart = finalPrice.substring(decimalIndex + 1);
      if (decimalPart.length > 2) {
        finalPrice = finalPrice.substring(0, decimalIndex + 3);
      } else if (decimalPart.length === 1) {
        finalPrice += '0';
      }
    }

    return finalPrice;
  }
}

const descriptionChange = value => {
  if (!value) return '';
  const parser = new DOMParser();
  const dom = parser.parseFromString(value, 'text/html'); // 받은 문자열을 html 형식으로 변경
  const parserText = dom.body.textContent || ''; // 이때 &amp; 같은 모든 엔티티 문자열이 &으로 변경됩니다!
  return parserText
    .replace(/<br\s*\/?>/gi, '')
    .replace(/<\/?[^>]+(>|$)/g, '')
    .replace(/\r\n/g, '<br>')
    .replace(/\n/g, '<br>')
    .replace(/<br>/g, '')
    .toLowerCase()
    .replace(/\s+/g, '');
};

function newImgArr(data) {
  return data
    ? data
        .map(item => item.img)
        .filter(img => img)
        .map(img => ({ img }))
    : [];
}

function newFileArr(data) {
  return data
    ? data
        .map(item => item.file_url)
        .filter(file_url => file_url)
        .map(file_url => ({ file_url }))
    : [];
}

export function createCompareObj(data, type) {
  const changeDesc = data?.description;

  if (type === 'changeValue') {
    const result = data;
    result.description = descriptionChange(result.description);
    result.detailImg = newImgArr(result.detailImg);
    result.files = newFileArr(result.files);

    return result;
  }
  const obj = {
    idStudio: data?.id_studio ?? '',
    idItem: data?.id_item ?? '',
    status: data?.status ?? '',
    name: data?.name ?? '',
    originalPrice: String(data?.original_price) === '0.00' ? 0 : Number(data?.original_price),
    discountRate: String(data?.discount_rate) === '0' ? 0 : Number(data?.discount_rate),
    finalPrice: calculateDiscountedPrice(data?.original_price ?? 0, data?.discount_rate ?? 0),
    idCategory: data?.category_id ?? '',
    tags: data?.tags ?? '',
    printingTime: data?.print_time ?? '',
    totalWeight: data?.total_weight ?? '',
    partSizeX: data?.lpart_size_x ?? '',
    partSizeY: data?.lpart_size_y ?? '',
    partSizeZ: data?.lpart_size_z ?? '',
    description: descriptionChange(changeDesc) ?? '',
    files: data?.files ? newFileArr(data.files) : [],
    detailImg: data?.detail_img ? newImgArr(data?.detail_img) : '',
    priceOption: data?.price_option ?? '',
    listImg4c: data?.list_img_4c ?? '',
    listImg3c: data?.list_img_3c ?? '',
    listImg: data?.list_img ?? '',
    listImg4g: data?.list_img_4g ?? '',
    listImg3g: data?.list_img_3g ?? '',
    listImg2: data?.list_img2 ?? '',
  };

  return obj;

  //중복된 Key 값
  // const obj = {
  //   status: data?.status,
  //   name: data?.name,
  //   printingTime: data?.print_time || '',
  //   totalWeight: data?.total_weight || '',
  //   partSizeX: data?.lpart_size_x || data?.part_size_x || '',
  //   partSizeY: data?.lpart_size_y || data?.part_size_y || '',
  //   partSizeZ: data?.lpart_size_z || data?.part_size_z || '',
  //   idCategory: data?.category_name ? data?.category_name : '',
  //   description: descriptionChange(data?.description),
  //   listImg: data?.list_img || data?.listImg || '',
  //   listImg2: data?.list_img2 === 'false' ? '' : data?.list_img2 || data?.listImg2 || '',
  //   files: data?.files?.map(item => item.file_url),
  // };

  // if (type === 'initialValue') {
  //   const initialObj = {
  //     finalPrice: calculateDiscountedPrice(
  //       Number(data?.original_price),
  //       Number(data?.discount_rate)
  //     ),
  //     tags: data?.tags?.replace(/\s+/g, ''),
  //     detailImg: data?.detail_img?.map(item => item.img),
  //     ...obj,
  //   };
  //   console.log(initialObj, 'initialObj');

  //   return obj;
  // } else if (type === 'changeValue') {
  //   const changeObj = {
  //     finalPrice: data?.finalPrice,
  //     tags: data?.tags
  //       .filter(item => item.isValid)
  //       .map(item => item.value)
  //       .join(',')
  //       .trim()
  //       .replace(/\s+/g, ''),
  //     detailImg: data?.detailImg.filter(item => item.value !== 'add').map(item => item.img),
  //     ...obj,
  //   };
  //   return data;
  // } else if (type === 'updateValue') {
  //   const updateObj = {
  //     finalPrice: data?.final_price === '0.00' ? '' : data?.final_price,
  //     tags: data?.tags?.replace(/\s+/g, ''),
  //     detailImg: data?.detail_img?.map(item => item.img),
  //     ...obj,
  //   };
  //   return updateObj;
  // }
}
