import { useContext, useEffect, useState } from 'react';
import { useLocation, useParams, useNavigate } from 'react-router-dom';
import Layout from '../../component/layout/Layout';
import { OPTION_ITEM_NEW, OPTION_ITEM_POPULAR, OPTION_ITEM_ALL } from './component/constant';
import SelectSort from './component/SelectSort';
import SelectType from './component/SelectType';
import './Category.css';
import styled from 'styled-components';
import {
  createCartAdd,
  findCategoryDetail,
  getCategories,
  getFreeLimit,
  getItemsV2,
  toggleItemLike,
} from '../../action/request';
import { gtagAddToCart, gtagViewItemList } from '../../action/gTag';
import { ls } from '../../constant';
import UserContext from '../../context/user/user';
import { useRecoilState } from 'recoil';
import { ctgSortAtom, likeAtom, scrollMoveAtom } from '../../atom/atom';
import { BannerWrapper, CollectionGrid } from './component/CollectionGridStyle';
import ModelCard from '../../component/common/itemCards/ModelCard';
import useClickHandler from '../../component/common/itemCards/useCardClickHandeler';
import useDownload from '../../hook/useDownload';
import { Webpage2StylesContainer } from '../../styles/Webpage2Styles';
import Pagenation from '../../component/designers/Pagenation';
import ModalContext from '../../context/modal/modal';
import CartContext from '../../context/cart.js/carts';
import { updateLikeState } from '../../util/updateLikeState';
import Selectefile from '../../component/selectefile/Selectefile';
import { CollectionItemListStyled } from './component/CollectionItemList';
import GoogleAds from '../../component/adv/GoogleAds';
const Category = () => {
  // const  isPopular = !!useLocation().state;
  const { oneClick, twoClick, handleClick } = useClickHandler();
  const [likeState, setLikeState] = useRecoilState(likeAtom);
  const {
    selectedId,
    selectedName,
    selectedModal,
    onClickDownload,
    setSelectedModal,
    setSelectedId,
  } = useDownload();
  const navigate = useNavigate();

  const isPopular = useLocation();
  const [type, setType] = useState();
  const [sort, setSort] = useState();
  const [page, setPage] = useState(1);
  const [items, setItems] = useState({ count: 0 });
  const [limit, setLimit] = useState({});
  const [categories, setCategories] = useState([]);
  const [categoryId, setCategoryId] = useState('');
  const { category } = useParams();
  const [sortCtgValue, setCtgSort] = useRecoilState(ctgSortAtom);
  const itemType = localStorage.getItem(ls.itemType) || OPTION_ITEM_ALL;
  const itemSort = localStorage.getItem(ls.itemSort) || OPTION_ITEM_NEW;
  const { state: userState } = useContext(UserContext);
  const { action: modalAction } = useContext(ModalContext);
  const { state: cartsState, action: cartsAction } = useContext(CartContext);
  const clickCart = async (e, price, id, inCart) => {
    // 이미 카트에 담겨있을 경우
    e.stopPropagation();
    if (inCart) return;

    if (!userState.isLogin) {
      // 로그인이 안되어 있을 때 로그인 모달을 보여준다.
      modalAction.setIsLogin(true);
    } else {
      try {
        const { data: res } = await createCartAdd(id);
        if (res) {
          // 기존 카트 갯수에 +1 을 한다.
          cartsAction.changeCartsCount(cartsState.cartsCount + 1);
          localStorage.setItem(ls.cartsCount, cartsState.cartsCount + 1);

          alert(res.result);

          // 기존 카드 상태에 카트 값 업데이트
          const newCards = items.results.map(card =>
            card.product_id === id
              ? {
                  ...card,
                  in_cart: !card.in_cart,
                }
              : card
          );
          setItems({ ...items, results: newCards });
          // gtag 장바구니 추가 태그
          gtagAddToCart({ value: Number(price), itemId: id });
        }
      } catch (e) {}
    }
  };

  useEffect(() => {
    const handleBeforeUnload = event => {
      navigate(isPopular.pathname, { replace: true });
    };

    window.addEventListener('beforeunload', handleBeforeUnload);

    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
    };
  }, []);

  useEffect(() => {
    // 홈에서 popular 섹션을 타고 왔을 경우
    if (isPopular?.state === 'popular' || isPopular?.search.includes('query')) {
      localStorage.setItem(ls.itemSort, OPTION_ITEM_POPULAR);
      setSort(OPTION_ITEM_POPULAR);
      setType(itemType);
    } else if (sortCtgValue) {
      setSort(sortCtgValue.sort);
      setType(sortCtgValue.type);
    } else {
      setSort(itemSort);
      setType(itemType);
    }

    setPage(Number(localStorage.getItem(ls.itemPage)) || 1);
  }, [
    localStorage.getItem(ls.itemPage),
    localStorage.getItem(ls.itemSort),
    localStorage.getItem(ls.itemType),
  ]);

  useEffect(() => {
    fetchData();
  }, [category, userState?.isLogin]);

  const typeChange = value => {
    setType(value);
    pageChange(1);
    localStorage.setItem(ls.itemType, value);
    setCtgSort({ type: value, sort: sort });
  };
  const sortChange = value => {
    setSort(value);
    pageChange(1);
    localStorage.setItem(ls.itemSort, value);
    navigate(isPopular.pathname, { replace: true });
    setCtgSort({ type: type, sort: value });
  };

  const pageChange = value => {
    setPage(value);
    localStorage.setItem(ls.itemPage, value);
  };

  const pageConfirm = value => {
    setPage(value);
    localStorage.setItem(ls.itemPage, value);
    updateItems();
  };

  const fetchData = async () => {
    try {
      const { data: resCategories } = await getCategories();
      setCategories(resCategories.data);
      const { data: resFreeLimit } = await getFreeLimit();
      setLimit(resFreeLimit.data);
      let cId = '';
      if (category) {
        const { data: resCategoryDetail } = await findCategoryDetail({ name: category });
        cId = resCategoryDetail.data.id_category;
        setCategoryId(cId);
      } else {
        setCategoryId('');
      }
      // if (type && page && sort) {
      //   updateItems(cId);
      // }
      // gtag 아이템 리스트 조회 태그
      gtagViewItemList(category);
      // console.log('tag test', window.location.href, category);
    } catch (e) {
      // TODO: 주석 풀기
      // report(e);
    }
  };

  const updateItems = async cId => {
    let id = cId;
    setCategoryId(r => {
      id = r ? r : id;
      return r;
    });

    if (id === undefined) return;
    // console.log('id', id);
    // console.log('sort', sort);
    // console.log('type', type);
    // console.log('page', page);

    const { data: resItems } = await getItemsV2({
      sort,
      idCategory: id,
      offset: (page - 1) * 24,
      size: 24,
      type,
    });
    setItems(resItems.data);
  };

  const clickLike = async (e, id, count) => {
    e.stopPropagation();
    if (!userState?.isLogin) {
      modalAction.setIsLogin(true);
    } else {
      const { data } = await toggleItemLike({ idItem: id });
      if (data) {
        const updatedCards = await updateLikeState({
          id: id,
          setLikeState: setLikeState,
          cards: items.results,
          count: count,
          data: data,
        });
        setItems({ ...items, results: updatedCards });
      }
    }
  };

  useEffect(() => {
    if (sort && type && (categoryId || isPopular?.pathname === '/categories'))
      updateItems(categoryId || '');
  }, [type, sort, page, categoryId]);

  useEffect(() => {
    setItems({});
  }, [categoryId, page, sort, type]);

  useEffect(() => {
    setCategoryId('');
  }, [isPopular?.pathname]);

  return (
    <>
      <Layout>
        <Webpage2StylesContainer>
          <CollectionItemListStyled id='main'>
            <div className='page-top page-top-with-breadcrumb'>
              <div className='container container-lg'>
                <h1 className='page-title02'>CATEGORY</h1>
                <h2 className='page-title-sub'>{category ? category : 'All'}</h2>
              </div>
            </div>

            <div className='main-content-with-aside'>
              <div className='main-content'>
                <section className='store-collection-list'>
                  <div className='container'>
                    <div
                      className='content-section content-section-has-top-nav'
                      style={{ minHeight: '900px' }}
                    >
                      <div className='product-list product-list-3'>
                        <BannerWrapper margin={'0px 0px 0px 0px'}>
                          <GoogleAds
                            type='typeA'
                            height={92}
                            key={page}
                            renderComponent={'category'}
                          />
                        </BannerWrapper>
                        <div>
                          <SelectBox>
                            <SelectType value={type} onchange={typeChange} />
                            <SelectSort value={sort} onchange={sortChange} />
                          </SelectBox>
                        </div>
                        <CollectionGrid>
                          {items?.results?.length > 0
                            ? items?.results?.map((item, idx) => {
                                const isBanner = idx === 8 || idx === 16;
                                return (
                                  <>
                                    {isBanner && (
                                      <BannerWrapper>
                                        <GoogleAds
                                          type='typeA'
                                          height={92}
                                          key={page}
                                          renderComponent={'category'}
                                        />
                                      </BannerWrapper>
                                    )}
                                    <ModelCard
                                      key={idx}
                                      handleClick={() => handleClick(idx)}
                                      twoClick={twoClick === idx}
                                      oneClick={oneClick === idx}
                                      data={item}
                                      clickCart={clickCart}
                                      clickLike={clickLike}
                                      onClickDownload={onClickDownload}
                                      buyStatus={item.buy_status}
                                      downloadStatus={item.download_status}
                                      isDesigner={item.isDesigner && userState.isLogin}
                                      renderComponent={'category'}
                                    />
                                  </>
                                );
                              })
                            : Array.from({ length: 24 }).map((_, idx) => (
                                <ModelCard loading={true} key={idx} />
                              ))}
                        </CollectionGrid>
                      </div>
                      {items.count > 0 && (
                        <Pagenation
                          count={items.count}
                          setPage={setPage}
                          page={page}
                          setPageNation={pageChange}
                          viewCount={24}
                          value={'categoryList'}
                        />
                      )}
                    </div>
                  </div>
                </section>
              </div>
            </div>
            {selectedId && selectedModal && (
              <Selectefile
                setSelectedModal={setSelectedModal}
                id={selectedId}
                setSelectedId={setSelectedId}
                selectedName={selectedName}
                status={true}
              />
            )}
          </CollectionItemListStyled>
        </Webpage2StylesContainer>
      </Layout>
    </>
  );
};

export default Category;

export const SelectBox = styled.div`
  display: flex;
  gap: 10px;
  margin-top: 22px;
  margin-bottom: 12px;

  justify-content: flex-end;
`;
