import { useContext, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import Layout from '../../component/layout/Layout';
import Categoryist from './component/CategoryList';
import { OPTION_ITEM_NEW, OPTION_ITEM_SET, OPTION_ITEM_SINGLE } from './component/constant';
import SelectSort from './component/SelectSort';
import SelectType from './component/SelectType';
import './Category.css';
import Pagination from './component/Pagination';
import ItemList from './component/ItemList';
import {
  createCartAdd,
  getCategories,
  getFreeLimit,
  getItems,
  getItemsV2,
  report,
  toggleItemLike,
} from '../../action/request';
import { gtagAddToCart, gtagViewItemList } from '../../action/gTag';
import { ls } from '../../constant';
import { SelectBox } from './Category';
import { BannerWrapper, CollectionGrid } from './component/CollectionGridStyle';
import ModelCard from '../../component/common/itemCards/ModelCard';
import { Webpage2StylesContainer } from '../../styles/Webpage2Styles';
import Pagenation from '../../component/designers/Pagenation';
import useClickHandler from '../../component/common/itemCards/useCardClickHandeler';
import { likeAtom, scrollMoveAtom } from '../../atom/atom';
import { useRecoilState } from 'recoil';
import useDownload from '../../hook/useDownload';
import UserContext from '../../context/user/user';
import ModalContext from '../../context/modal/modal';
import CartContext from '../../context/cart.js/carts';
import { updateLikeState } from '../../util/updateLikeState';
import Selectefile from '../../component/selectefile/Selectefile';
import CardAdsBox from '../GoogleAds/CardAdsBox';
import { CollectionItemListStyled } from './component/CollectionItemList';
import GoogleAds from '../../component/adv/GoogleAds';
const SetItem = ({}) => {
  const { oneClick, twoClick, handleClick } = useClickHandler();
  const [likeState, setLikeState] = useRecoilState(likeAtom);
  const { state: userState } = useContext(UserContext);
  const { action: modalAction } = useContext(ModalContext);
  const { state: cartsState, action: cartsAction } = useContext(CartContext);
  const {
    selectedId,
    selectedName,
    selectedModal,
    onClickDownload,
    setSelectedModal,
    setSelectedId,
  } = useDownload();
  const [type, setType] = useState(OPTION_ITEM_SINGLE);
  const [categories, setCategories] = useState([]);
  const [sort, setSort] = useState('');
  const [page, setPage] = useState(0);
  const [items, setItems] = useState({ count: 0 });
  const [limit, setLimit] = useState({});
  const { category } = useParams();

  // 아이템 페이지네이션, filter 값이 있을 경우 해당 값으로 상태값 초기화
  useEffect(() => {
    setSort(localStorage.getItem(ls.itemSort) || OPTION_ITEM_NEW);
    setPage(Number(localStorage.getItem(ls.itemPage)) || 1);
  }, [localStorage.getItem(ls.itemPage), localStorage.getItem(ls.itemSort)]);

  const sortChange = value => {
    setSort(value);
    pageChange(1);
    localStorage.setItem(ls.itemSort, value);
  };

  const pageChange = value => {
    setPage(value);
    localStorage.setItem(ls.itemPage, value);
  };

  const pageConfirm = value => {
    setPage(value);
    localStorage.setItem(ls.itemPage, value);
    updateItems();
  };

  const fetchData = async () => {
    try {
      const { data: categories } = await getCategories();
      setCategories(categories.data);
      const { data: resLimit } = await getFreeLimit();
      setLimit(resLimit.data);

      if (page && sort) {
        updateItems();
      }

      // gtag 아이템 리스트 조회 태그
      gtagViewItemList('set-items');

      // console.log('tag test', window.location.href, 'set-items');
    } catch (e) {
      // TODO: 주석 풀기
      // report(e);
    }
  };
  useEffect(() => {
    fetchData();
  }, [userState?.isLogin]);

  useEffect(() => {
    if (page && sort) {
      updateItems();
    }
  }, [page, sort]);

  const updateItems = async cId => {
    let id = cId;
    const { data: resItems } = await getItemsV2({
      sort,
      idCategory: '',
      offset: (page - 1) * 24,
      size: 24,
      type: OPTION_ITEM_SET,
    });
    setItems(resItems.data);
  };

  useEffect(() => {
    setItems({});
  }, [page, sort, type]);

  const clickCart = async (e, price, id, inCart) => {
    // 이미 카트에 담겨있을 경우
    e.stopPropagation();
    if (inCart) return;

    if (!userState.isLogin) {
      // 로그인이 안되어 있을 때 로그인 모달을 보여준다.
      modalAction.setIsLogin(true);
    } else {
      try {
        const { data: res } = await createCartAdd(id);
        if (res) {
          // 기존 카트 갯수에 +1 을 한다.
          cartsAction.changeCartsCount(cartsState.cartsCount + 1);
          localStorage.setItem(ls.cartsCount, cartsState.cartsCount + 1);

          alert(res.result);

          // 기존 카드 상태에 카트 값 업데이트
          const newCards = items.results.map(card =>
            card.product_id === id
              ? {
                  ...card,
                  in_cart: !card.in_cart,
                }
              : card
          );
          setItems({ ...items, results: newCards });
          // gtag 장바구니 추가 태그
          gtagAddToCart({ value: Number(price), itemId: id });
        }
      } catch (e) {}
    }
  };

  const clickLike = async (e, id, count) => {
    e.stopPropagation();
    if (!userState?.isLogin) {
      modalAction.setIsLogin(true);
    } else {
      const { data } = await toggleItemLike({ idItem: id });
      if (data) {
        const updatedCards = await updateLikeState({
          id: id,
          setLikeState: setLikeState,
          cards: items.results,
          count: count,
          data: data,
        });
        setItems({ ...items, results: updatedCards });
      }
    }
  };

  return (
    <>
      <Layout>
        <Webpage2StylesContainer>
          <CollectionItemListStyled id='main'>
            <div className='page-top page-top-with-breadcrumb'>
              <div className='container container-lg'>
                <h1 className='page-title'>SET ITEMS</h1>
              </div>
            </div>

            <div className='main-content-with-aside'>
              {/* <Categoryist current={'set'} data={categories.items} /> */}
              <div className='main-content'>
                <section className='store-collection-list'>
                  <div className='container'>
                    <div
                      className='content-section content-section-has-top-nav'
                      style={{ minHeight: '900px' }}
                    >
                      <div className='product-list product-list-3'>
                        <BannerWrapper margin={'0px 0px 0px 0px'}>
                          <GoogleAds type='typeA' height={92} renderComponent={'category'} />
                        </BannerWrapper>
                        <div>
                          <SelectBox>
                            <SelectSort
                              value={sort}
                              onchange={sortChange}
                              renderComponent={'setItem'}
                            />
                          </SelectBox>
                        </div>
                        <CollectionGrid>
                          {items?.results?.length > 0
                            ? items?.results?.map((item, idx) => {
                                const isBanner = idx === 8 || idx === 16;
                                return (
                                  <>
                                    {isBanner && (
                                      <BannerWrapper>
                                        <GoogleAds
                                          type='typeA'
                                          height={92}
                                          renderComponent={'category'}
                                        />
                                      </BannerWrapper>
                                    )}
                                    <ModelCard
                                      key={idx}
                                      handleClick={() => handleClick(idx)}
                                      twoClick={twoClick === idx}
                                      oneClick={oneClick === idx}
                                      data={item}
                                      clickCart={clickCart}
                                      clickLike={clickLike}
                                      onClickDownload={onClickDownload}
                                      buyStatus={item.buy_status}
                                      downloadStatus={item.download_status}
                                      isDesigner={item.isDesigner && userState.isLogin}
                                      renderComponent={'setItem'}
                                    />
                                  </>
                                );
                              })
                            : Array.from({ length: 24 }).map((_, idx) => (
                                <ModelCard loading={true} key={idx} />
                              ))}
                        </CollectionGrid>
                      </div>

                      {items.count > 0 && (
                        <Pagenation
                          count={items.count}
                          setPage={setPage}
                          page={page}
                          setPageNation={pageChange}
                          viewCount={24}
                          value={'setItems'}
                        />
                      )}
                    </div>
                  </div>
                </section>
              </div>
            </div>
            {selectedId && selectedModal && (
              <Selectefile
                setSelectedModal={setSelectedModal}
                id={selectedId}
                setSelectedId={setSelectedId}
                selectedName={selectedName}
                status={true}
              />
            )}
          </CollectionItemListStyled>
        </Webpage2StylesContainer>
      </Layout>
    </>
  );
};

export default SetItem;
