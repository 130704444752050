import React, { useEffect, useRef } from 'react';
import styled from 'styled-components';

const GoogleAds = ({ type, marginBottom, renderComponent }) => {
  const adRefs = useRef([]);

  useEffect(() => {
    const googleAdsScript = document.body.querySelector(
      'script[src*="https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js?client=ca-pub-3499757455506110"]'
    );

    const initializeAd = ad => {
      try {
        (window.adsbygoogle = window.adsbygoogle || []).push({});
      } catch (e) {
        console.error('AdSense error', e);
      }
    };
    if (!googleAdsScript) {
      const script = document.createElement('script');
      script.src =
        'https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js?client=ca-pub-3499757455506110';
      script.async = true;
      script.crossOrigin = 'anonymous';
      document.body.appendChild(script);

      script.onload = () => {
        adRefs.current.forEach(ref => {
          if (ref.current) {
            initializeAd(ref.current);
          }
        });
      };
    } else {
      (window.adsbygoogle = window.adsbygoogle || []).push({});
    }

    const checkAdsStatus = isLastCheck => {
      adRefs.current.forEach(ref => {
        if (ref.current) {
          const adStatus = ref.current.getAttribute('data-ad-status');
          if (adStatus === 'unfilled' || adStatus === null) {
            const parent = ref.current.parentNode;

            if (isLastCheck) {
              if (adStatus === 'unfilled' || adStatus === null) {
                const container = parent.closest('.google-ads-container');
                if (container) {
                  container.style.backgroundColor = 'transparent';
                }
              }
            }

            ref.current.remove();

            const newAd = document.createElement('ins');
            newAd.className = `adsbygoogle adslot_${type}`;
            newAd.setAttribute('data-ad-client', 'ca-pub-3499757455506110');
            newAd.setAttribute('data-ad-slot', ref.current.getAttribute('data-ad-slot'));
            newAd.style.display = 'block';
            parent.appendChild(newAd);

            initializeAd(newAd);

            ref.current = newAd;
          }
        }
      });
    };

    let counter = 0; // 5번 반복
    const intervalId = setInterval(() => {
      if (counter < 5) {
        checkAdsStatus(counter === 4);
        counter++;
      } else {
        clearInterval(intervalId);
      }
    }, 8000);
    return () => {
      clearInterval(intervalId);
      if (googleAdsScript && googleAdsScript.parentNode) {
        document.body.removeChild(googleAdsScript);
      }
    };
  }, []);

  const assignRef = index => {
    if (!adRefs.current[index]) {
      adRefs.current[index] = React.createRef();
    }
    return adRefs.current[index];
  };

  return (
    <GoogleAdsContainer className={`google-ads-container ${renderComponent}`}>
      {type === 'typeA' && (
        <div className='type-a-wrapper'>
          <ins
            ref={assignRef(0)}
            className='adsbygoogle adslot_typeA'
            data-ad-client='ca-pub-3499757455506110'
            data-ad-slot='6624662873'
          ></ins>
        </div>
      )}
      {type === 'typeB' && (
        <div className='type-b-wrapper'>
          <ins
            ref={assignRef(0)}
            className='adsbygoogle adslot_typeB'
            data-ad-client='ca-pub-3499757455506110'
            data-ad-slot='6624662873'
          ></ins>
          <ins
            ref={assignRef(1)}
            className='adsbygoogle adslot_typeB'
            data-ad-client='ca-pub-3499757455506110'
            data-ad-slot='6624662873'
          ></ins>
        </div>
      )}
      {type === 'typeC' && (
        <div
          className='type-c-wrapper'
          style={{ marginBottom: marginBottom ? `${marginBottom}px` : '0' }}
        >
          <ins
            ref={assignRef(0)}
            className='adsbygoogle adslot_typeC'
            data-ad-client='ca-pub-3499757455506110'
            data-ad-slot='6624662873'
          ></ins>
        </div>
      )}
      {type === 'typeD' && (
        <TypeDWrapper
          className='type-d-wrapper'
          style={{ marginBottom: marginBottom ? `${marginBottom}px` : '0' }}
        >
          <ins
            ref={assignRef(0)}
            className='adsbygoogle adslot_typeD'
            data-ad-client='ca-pub-3499757455506110'
            data-ad-slot='6624662873'
          ></ins>
          <ins
            ref={assignRef(1)}
            className='adsbygoogle adslot_typeD'
            data-ad-client='ca-pub-3499757455506110'
            data-ad-slot='6624662873'
          ></ins>
          <ins
            ref={assignRef(2)}
            className='adsbygoogle adslot_typeD'
            data-ad-client='ca-pub-3499757455506110'
            data-ad-slot='6624662873'
          ></ins>
          <ins
            ref={assignRef(3)}
            className='adsbygoogle adslot_typeD'
            data-ad-client='ca-pub-3499757455506110'
            data-ad-slot='6624662873'
          ></ins>
        </TypeDWrapper>
      )}
      {type === 'collectionList' && (
        <div className='adslot-collection-list-wrapper'>
          <ins
            ref={assignRef(0)}
            className='adsbygoogle adslot_collectionList'
            data-ad-client='ca-pub-3499757455506110'
            data-ad-slot='6624662873'
          ></ins>
        </div>
      )}

      {type === 'typeE' && (
        <div
          className='type-e-wrapper'
          style={{ marginBottom: marginBottom ? `${marginBottom}px` : '0' }}
        >
          <ins
            ref={assignRef(0)}
            className='adsbygoogle adslot_typeE'
            data-ad-client='ca-pub-3499757455506110'
            data-ad-slot='6624662873'
          ></ins>
        </div>
      )}
    </GoogleAdsContainer>
  );
};

export default GoogleAds;

const GoogleAdsContainer = styled.div`
  /* unfilled 상태일 때의 스타일 */
  background-color: #eeeeee;
`;

const TypeDWrapper = styled.div`
  height: 2500px; // 전체 높이 고정
  width: 160px;
  position: relative;
  margin: 0 auto;

  ins.adsbygoogle {
    position: absolute;
    width: 160px !important;
    height: 600px !important;
    display: block !important;
    margin: 0 !important;
    padding: 0 !important;

    &:nth-child(1) {
      top: 0;
    }
    &:nth-child(2) {
      top: 633px;
    }
    &:nth-child(3) {
      top: 1266px;
    }
    &:nth-child(4) {
      top: 1900px;
    }
  }
`;
