import React from 'react';
import Skeleton from 'react-loading-skeleton';
import { getTitle } from '../../section/util';
import { theme } from '../../../styles/theme';
import { Link } from 'react-router-dom';
import { ImgStyled } from '../../../styles/ImgStyled';
import { CATEGORIES, COLLECTIONS } from '../../../route/constant';

const SectionHeader = ({
  isSkeletonLoading,
  data,
  isMobile,
  skeleton,
  goTo,
  sectionValue,
  sectionHeader,
}) => {
  const SKELETON_BANNER_PADDINGTOP_PC = '32.33%';
  const SKELETON_BANNER_PADDINGTOP_MO = '58.5%';

  return (
    <div>
      <div className='titleRow'>
        <div className='title'>
          {!isSkeletonLoading ? (
            <>
              {getTitle(data?.title).combinedWords} <br className='show500' />
              {getTitle(data?.title).lastWord}
            </>
          ) : (
            <>
              <Skeleton
                width={skeleton}
                baseColor={theme.skeleton_dark_gray}
                highlightColor={theme.sleleton_dark_gray_highlight}
              />
              {isMobile && <br className='show500' />}
            </>
          )}
        </div>
      </div>
      <div className='secondRow' style={{ height: isMobile && isSkeletonLoading && '0' }}>
        {!isSkeletonLoading ? (
          <Link
            to={`${sectionValue === 'A' || sectionValue === 'B' ? data.viewAll : CATEGORIES}${
              getTitle(data?.title).combinedWords === 'FEATURED' ? `?query='popular'` : ''
            }`}
            className='viewAll'
            onClick={e =>
              goTo(e, sectionValue === 'A' || sectionValue === 'B' ? data?.viewAll : 'viewAll')
            }
          >
            <span>VIEW ALL </span>
          </Link>
        ) : (
          <Skeleton
            className='skeleton--viewAll'
            width={94}
            baseColor={theme.skeleton_dark_gray}
            highlightColor={theme.sleleton_dark_gray_highlight}
          />
        )}
      </div>

      {sectionHeader && (
        <div className='bannersWrap '>
          {!isSkeletonLoading ? (
            <div
              onClick={e => goTo(e, `${COLLECTIONS}/${data?.banner?.url}`)}
              className='bannerImg'
            >
              <ImgStyled src={!isMobile ? data.banner.imgDesktop : data.banner.imgMobile} alt='' />
            </div>
          ) : (
            <Skeleton
              style={{
                paddingTop: isMobile
                  ? SKELETON_BANNER_PADDINGTOP_MO
                  : SKELETON_BANNER_PADDINGTOP_PC,
                height: 0,
                borderRadius: isMobile ? 0 : '8px',
              }}
              baseColor={theme.skeleton_dark_gray}
              highlightColor={theme.sleleton_dark_gray_highlight}
            />
          )}
        </div>
      )}
    </div>
  );
};

export default SectionHeader;
