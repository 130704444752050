import axios from 'axios';

import { ins, s } from 'react-dom-factories';
import { ls } from '../constant';
import { RETURN_CODE } from './constant';

export const HOSTNAME = window.location.hostname;
export const LOCALHOST = 'localhost';
// export const LOCALHOST = '192.168.219.100';
export const DEV_HOSTNAME = 'dev-java.fab365.net'; // 개발 도메인
export const DEV2_HOSTNAME = 'dev2.fab365.net'; // 개발2 도메인
const PRODUCTION_HOSTNAME = 'fab365.net'; // 운영 url
const PRODUCTION_API_URL = 'https://fab365.net/'; // 운영 api
const SERVER_API_URL = `https://${HOSTNAME}/`; // 운영 api
// const DEV_SERVER_API_URL = 'https://dev-java.fab365.net/'; // 개발 api
const DEV_SERVER_API_URL = 'https://dev2.fab365.net/'; // 개발 api 2
// const MOBILE_TEST_IP = '172.30.1.72'; // 모바일 테스트용 ip 1

export const baseUrl =
  HOSTNAME === LOCALHOST
    ? DEV_SERVER_API_URL
    : HOSTNAME === PRODUCTION_HOSTNAME
    ? PRODUCTION_API_URL
    : SERVER_API_URL;

const isLoginUsingToken =
  localStorage.getItem(ls.accessToken) !== 'undefined' &&
  localStorage.getItem(ls.accessToken) !== 'null' &&
  localStorage.getItem(ls.accessToken);

const instance = axios.create({
  baseURL: baseUrl,
  timeout: 50000,
  headers: { 'Cache-Control': 'no-cache', Pragma: 'no-cache', Expires: '0' },
});

const privateApi = axios.create({
  baseURL: baseUrl,
  headers: {
    Authorization: `Bearer ${localStorage.getItem(ls.accessToken)}`,
    'Cache-Control': 'no-cache',
    Pragma: 'no-cache',
    Expires: '0',
  },
});

const postRefreshToken = (from = 'W') => {
  const response = privateApi.post('/api/v3_1/refresh', {
    refresh_token: localStorage.getItem(ls.refreshToken),
    from: from,
  });
  return response;
};

function getCookie(name) {
  const value = `; ${document.cookie}`;
  const parts = value.split(`; ${name}=`);
  if (parts.length === 2) {
    return parts.pop().split(';').shift();
  }
  return null;
}
privateApi.interceptors.request.use(config => {
  // console.log(
  //   'new',
  //   localStorage.getItem(ls.accessToken),
  //   '구분',
  //   localStorage.getItem(ls.refreshToken)
  // );
  const token = localStorage.getItem(ls.accessToken);
  config.headers.Authorization = `Bearer ${token}`;
  return config;
});

privateApi.interceptors.response.use(
  response => {
    return response;
  },
  async error => {
    const { config, response } = error;
    // console.log('token error response', response);
    const token = localStorage.getItem(ls.accessToken);
    config.headers.Authorization = `Bearer ${token}`;

    if (response.data.returnCode === RETURN_CODE.E00001) {
      const originRequest = config;
      try {
        const autoLogin = getCookie('autoLogin') === 'true';
        const from = autoLogin ? 'A' : 'W';

        const tokenResponse = await postRefreshToken(from);
        // console.log('refresh token response', tokenResponse);
        if (tokenResponse.data?.data?.access_token) {
          const newAccessToken = tokenResponse.data.data.access_token;
          localStorage.setItem(ls.accessToken, tokenResponse.data.data.access_token);
          localStorage.setItem(ls.refreshToken, tokenResponse.data.data.refresh_token);
          axios.defaults.headers.common.Authorization = `Bearer ${newAccessToken}`;
          originRequest.headers.Authorization = `Bearer ${newAccessToken}`;

          return axios(originRequest);
        } else {
          // console.log('error1');
          localStorage.removeItem(ls.accessToken);
          localStorage.removeItem(ls.refreshToken);
          window.location.replace('/');
        }
      } catch (error) {
        // console.log('error2');
        localStorage.removeItem(ls.accessToken);
        localStorage.removeItem(ls.refreshToken);
        window.location.replace('/');
      }
    }

    return Promise.reject(error);
  }
);

const analyze = e => {};

const report = e => {
  const url = 'https://fab365.net/api/analyze';

  var data = {
    data: e,
  };

  axios.post(url, JSON.stringify(data), {
    headers: {
      'Content-Type': 'application/json',
    },
  });
};

// 메인 페이지
const getMainBanner = () => {
  return instance.get('/api/banner/main');
};

const getMainSlide = () => {
  return instance.get('/api/slide/main');
};

const getSideSlide = () => {
  return instance.get('/api/slide/slide');
};

// 카테고리
const getCategories = () => {
  return instance.get('/api/category/list');
};

const findCategoryDetail = ({ name }) => {
  return instance.get(`/api/category/find?name=${encodeURIComponent(name)}`);
};

const getNotice = () => {
  return instance.get(`/api/notice/main`);
};

// 프린터 세팅
const getPrinters = () => {
  return instance.get('/api/printers');
};

const getSlicers = () => {
  return instance.get('/api/slicers');
};

// 레이아웃
const getTags = () => {
  return instance.get('/api/tags');
};

// 스튜디오
const getStudio = ({ studioId }) => {
  return instance.get(`/api/studio/${studioId}`);
};

// 리뷰
const getRandomReviews = () => {
  if (isLoginUsingToken) {
    return privateApi.get('/api/review/random');
  } else {
    return instance.get('/api/review/random');
  }
};

// 아이템
const getNewItems = () => {
  return instance.get('/api/item/new');
};

const getPopularItems = () => {
  return instance.get('/api/item/popular');
};

// 상품 검색 (상세페이지 > 추천상품)
const searchItems = ({ ids }) => {
  const idString = ids ? ids.join(',') : '';
  return instance.post(`/api/item/search?idItems=${idString}`);
};

// WIP
const uploadItemImage = async ({ file }) => {
  const { data: res } = await getPresignedUrl({ filename: file.name });
  const parsed = res.presignedUrl.split('?');
  const key = 'upload/' + parsed[0].split('upload/')[1];
  const img = new Blob([file]);

  return await fetch(res.presignedUrl, {
    method: 'PUT',
    header: {},
    body: img,
  }).then(async () => {
    return await getItemImage({
      key: key,
      filename: file.name,
    });
  });
};
const getPresignedUrl = ({ filename }) => {
  return privateApi.post(`/api/upload-url`, {
    action: 'upload-url',
    params: { prefix: 'itemComment', filename },
  });
};

const getItemImage = ({ key }) => {
  return privateApi.post(
    `/api/upload-comment`,
    {
      action: 'upload-comment',
      params: {
        key: key,
      },
    },
    {
      headers: {
        'Content-Type': 'application/json',
      },
    }
  );
};

const createItemComment = ({ root, parent, msg, medias, imgs }) => {
  return privateApi.post(`/api/v3_1/comment-write-item`, {
    action: 'itemComment-write',
    params: {
      root,
      parent,
      msg,
      'imgs[]': imgs,
      medias,
    },
  });
};

const deleteItemComment = ({ oidComment }) => {
  return privateApi.post(`/api/v3_1/comment-delete-item`, {
    action: 'itemComment-delete',
    params: {
      oidComment,
    },
  });
};

const editItemComment = ({ comment, msg, medias, imgs }) => {
  return privateApi.post(`/api/v3_1/comment-edit-item`, {
    action: 'itemComment-edit',
    params: {
      comment,
      msg,
      'imgs[]': imgs,
      medias,
    },
  });
};

const replyItemComment = ({ root, parent, msg, medias, imgs }) => {
  return privateApi.post(`/api/v3_1/comment-write-item`, {
    action: 'itemComment-write',
    params: {
      root,
      parent,
      msg,
      'imgs[]': imgs,
      medias,
    },
  });
};

// collection 목록 조회
const getCollectionList = ({ page }) => {
  return instance.get(`/api/item/collection?page=${page}`);
};
// collection 아이템 조회
const getCollectionItems = ({ page, id }) => {
  return instance.get(`/api/item/collections?page=${page}&id=${id}`);
};
// collection 아이템 조회 NEW
const getCollectionItemsNew = ({ page, url }) => {
  return instance.get(`/api/item/collections/${url}?page=${page}`);
};

const getCollectionItemsNewV2 = ({ page, url }) => {
  return privateApi.get(`/api/v2/item/collections/${url}?page=${page}`);
};
// collection 아이템 상세 조회
const getCollectionDetail = ({ id }) => {
  return instance.get(`/api/item/collection/detail?id=${id}`);
};
// 아이템 상세 조회
const getItemDetail = ({ id, isLoginState }) => {
  if (isLoginState) {
    // return privateApi.get(`/api/item/detail/${id}`);
    return privateApi.get(`/api/v2/item/detail/${id}`);
  } else {
    // return instance.get(`/api/item/detail/${id}`);
    return instance.get(`/api/v2/item/detail/${id}`);
  }
};

const getItemInfo = ({ id }) => {
  return instance.get(`/api/item/data/${id}`);
};

// 아이템에 대한 좋아요 개수 조회
const getItemLike = ({ id }) => {
  return instance.get(`/api/item/like/${id}`);
};

const getItemOwn = ({ idItem }) => {
  return privateApi.post(`/api/item/isOwn?idItem=${idItem}`);
};

// 사용자가 눌렀는지 확인
const isItemLike = ({ idItem }) => {
  return privateApi.post(`/api/item/like?idItem=${idItem}`);
};
// 아이템 좋아요 버튼 토글
const toggleItemLike = ({ idItem }) => {
  return privateApi.post(`/api/item/like/toggle?idItem=${idItem}`);
};

const getItemInfoV2 = ({ id }) => {
  return instance.get(`/api/item/data/v2/${id}`, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });
};

const getFreeLimit = () => {
  return privateApi.post(`/api/item/free`, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });
};

const getItems = ({ sort, idCategory = '', offset, size, type }) => {
  // console.log(sort, idCategory, offset, size, type);
  return instance.post(
    `/api/item/offer?sort=${sort}&idCategory=${idCategory}&offset=${offset}&size=${size}&type=${type}`
  );
};

const getItemsV2 = ({ sort, idCategory = '', offset, size, type }) => {
  return privateApi.post(
    `/api/v2/item/offer?sort=${sort}&idCategory=${idCategory}&offset=${offset}&size=${size}&type=${type}`
  );
};

const getItemReview = ({ idItem, page, size }) => {
  if (isLoginUsingToken) {
    return privateApi.post(`/api/v2/item/detail/${idItem}/review?page=${page}&size=${size}`);
  } else {
    return instance.post(`/api/v2/item/detail/${idItem}/review?page=${page}&size=${size}`);
  }
};

const getItemComments = ({ idItem, page }) => {
  return instance.post(`/api/v2/item/detail/${idItem}/comment?page=${page}&size=3`);
};

const getItemFreeTicketCount = ({ idProduct }) => {
  return privateApi.post(
    `/api/v3_1/freeTicketCount`,
    { idProduct },
    {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    }
  );
};

// 주문 목록
const getOrders = ({ page }) => {
  return privateApi.get(`/api/my/orders?page=${page}`);
};

const getOrderDetail = ({ oid }) => {
  return privateApi.get(`api/my/orders/${oid}`);
};

// 사용자 프로필

const getUserProfile = () => {
  return privateApi.get(`api/my/profile`);
};

const downloadItem = ({ idItem }) => {
  return privateApi.get(`/api/my/downloads/${idItem}`);
};

// 사용자 티켓 정보

const getMyTickets = () => {
  return privateApi.get(`api/my/free_tickets`);
};

// 사용자 리뷰 정보

const getMyReviews = ({ page }) => {
  return privateApi.get(`api/my/reviews?page=${page}`);
};

// 사용자 리뷰 수정화면
const getMyReviewDetail = ({ oid }) => {
  return privateApi.get(`api/my/reviews/edit/${oid}`);
};

// 로그인 로직
const login = params => {
  return instance.post(
    `/api/v3_1/login`,
    { ...params },
    {
      headers: {
        'Content-Type': 'application/json',
      },
    }
  );
};

// 로그아웃
const logout = refreshToken => {
  return privateApi.post(
    '/api/v3_1/logout',
    { refresh_token: refreshToken },
    {
      headers: {
        'Content-Type': 'application/json',
      },
    }
  );
};

// 소셜 구글 로그인
const googleLogin = (code, from = 'W') => {
  return instance.post(`/api/sns/google/callback`, {
    token: code,
    from: from,
  });
};
// 소셜 애플 로그인
const appleLogin = (code, from = 'W') => {
  return instance.get(`/api/sns/apple/login?code=${code}&from=${from}`);
};
// 소셜 깃헙 로그인
const githubLogin = (code, from = 'W') => {
  return instance.get(`/api/sns/github/login?code=${code}&from=${from}`);
};
// 소셜 야후 로그인
const yahooLogin = (code, from = 'W') => {
  return instance.get(`/api/sns/yahoo/login?code=${code}&from=${from}`);
};
// 소셜 트위터 로그인
const twitterLogin = (token, verifier, from = 'W') => {
  return instance.get(
    `/api/sns/twitter/login?oauth_token=${token}&oauth_verifier=${verifier}&from=${from}`
  );
};
// 소셜 페이스북 로그인
const facebookLogin = (code, from = 'W') => {
  return instance.get(`/api/sns/facebook/login?code=${code}&from=${from}`);
};

// 사용자 이메일 리셋
const resetEmail = email => {
  return instance.get(`/api/v3/resetPassword?email=${email}&siteUrl=${HOSTNAME}`);
};

const resetPassword = (code, password, passwordConfirm) => {
  return instance.post(
    `/api/v3`,
    {
      query: { action: 'reset-password', params: { code, password, passwordConfirm } },
    },
    {
      headers: {
        'Content-Type': 'application/json',
      },
    }
  );
};

// 회원가입
const signup = params => {
  return instance.post(`/api/v3_1/signup`, params, {
    headers: {
      'Content-Type': 'application/json',
    },
  });
};

// 소셜회원가입
const snsSignup = params => {
  return instance.post(`/api/v3_1/snssignup`, params, {
    headers: {
      'Content-Type': 'application/json',
    },
  });
};

// 사용자 정보조회
const getUserInfo = () => {
  return privateApi.post(`/api/v3_1/user/getInfo`, {
    headers: {
      'Content-Type': 'application/json',
    },
  });
};

// profile 정보 변경
const updateUser = params => {
  //alert(JSON.stringify(params));
  return privateApi.post(
    `/api/v3_1/my_profile`,
    { ...params },
    {
      headers: {
        'Content-Type': 'application/json',
      },
    }
  );
};

// profile fab 이메일 구독 여부 저장
const updateSubscribe = params => {
  return privateApi.post(
    `/api/v3_1/subscribe`,
    { ...params },
    {
      headers: {
        'Content-Type': 'application/json',
      },
    }
  );
};

// 사용자 프로필 프린터 저장
const updatePrinter = params => {
  return privateApi.post(
    `/api/v3_1/userPrinter-save-all`,
    { ...params },
    {
      headers: {
        'Content-Type': 'application/json',
      },
    }
  );
};

// 사용자 프로필 프린터 다운로드
const downloadPrinter = params => {
  return privateApi.post(
    `/api/v2/printer-profile-download-v2`,
    { ...params },
    {
      headers: {
        'Content-Type': 'application/json',
      },
    }
  );
};

// 사용자 이메일 인증
const verifyEmail = () => {
  return privateApi.post(`/api/v3_1/email-verify`);
};

// 수신된 이메일에서 호출하는 url 검증
const validateVerifyEmail = code => {
  return instance.get(`/api/email-verify?code=${code}`);
};

const resendVerifyEmail = email => {
  return instance.get(`/api/email-verify-resend?email=${email}`);
};

// 회원 탈퇴 (계정 삭제)
const userDelete = refreshToken => {
  return privateApi.post(
    `/api/v3_1/profile-close-account`,
    { refresh_token: refreshToken },
    {
      headers: {
        'Content-Type': 'application/json',
      },
    }
  );
};

const getSalse = () => {
  return privateApi.get(`/api/my/sales`);
};

// 특정 월별 정보 조회
const selectSales = pathName => {
  return privateApi.get(`/api/my/sales/daily/${pathName}`);
};

// 특정 연도별 정보 조회
const yearlySales = year => {
  return privateApi.get(`/api/monthly-sales-by-year?year=${year}`);
};

// Balance History 정보
const getBalanceHistory = (startDate, endDate) => {
  return privateApi.get(`api/sales-daily-history?start=${startDate}&end=${endDate}`);
  // return privateApi.get(`api/sales-daily-history?start=2001-01-01&end=${endDate}`);
};

// Sales Products 정보
const getProductSales = () => {
  return privateApi.get(`/api/sales-products`);
};

const getProductDetail = pidNo => {
  return privateApi.get(`/api/sales-product-history?pid=${pidNo}`);
};

// 정산요청 api
const requestWithdrawal = params => {
  return privateApi.post(
    `/api/request-withdrawal`,
    { ...params },
    {
      headers: {
        'Content-Type': 'application/json',
      },
    }
  );
};

// 정산요청 api (email 보내기)
const emailRequestWithdrawal = params => {
  return privateApi.post(
    `/api/email-request-withdrawal`,
    { ...params },
    {
      headers: {
        'Content-Type': 'application/json',
      },
    }
  );
};
// 사용자 dowmloads 리스트 조회
const getDownloads = ({ page }) => {
  return privateApi.get(`/api/my/downloads?page=${page}`);
};

// 사용자 리뷰 작성화면
const getSelectItems = () => {
  return privateApi.get('/api/my/reviews/write');
};

const getSelectReviewItems = id => {
  return privateApi.get(`/api/my/reviews/write/${id}`);
};

// 사용자 리뷰 작성
const reviewAdd = params => {
  return privateApi.post(
    `/api/review-add`,
    { ...params },
    {
      headers: {
        'Content-Type': 'application/json',
      },
    }
  );
};

// 사용자 리뷰 수정
const reviewEdit = params => {
  return privateApi.post(
    `/api/v3_1/review-edit`,
    { ...params },
    {
      headers: {
        'Content-Type': 'application/json',
      },
    }
  );
};

// 사용자 review 업로드
const ReviewUploadItemImage = async ({ file }) => {
  const { data: res } = await getPresignedUrl({ filename: file.name });
  const parsed = res.presignedUrl.split('?');
  const key = 'upload/' + parsed[0].split('upload/')[1];
  const img = new Blob([file]);

  return await fetch(res.presignedUrl, {
    method: 'PUT',
    header: {},
    body: img,
  }).then(async () => {
    return await getReviewItemImage({
      key: key,
      filename: file.name,
    });
  });
};

// 사용자 review 업로드
const getReviewItemImage = ({ key, filename }) => {
  return privateApi.post(
    `/api/upload-review`,
    {
      action: 'upload-comment',
      params: {
        key: key,
      },
    },
    {
      headers: {
        'Content-Type': 'application/json',
      },
    }
  );
};

// 사용자 리뷰 삭제
const deleteReview = oId => {
  return privateApi.post(`/api/v3_1/review-delete?oid=${oId}`);
};

// 사용자 like 리스트 조회
const getSelectLikes = () => {
  return privateApi.get(`/api/my/likes`);
};

// 사용자 like 삭제
const likesDelete = idItem => {
  return privateApi.post(`/api/v3/like-delete?item=${idItem}`);
};

// 사용자 reviews리스트 조회
const getMyRequest = ({ page }) => {
  return privateApi.get(`api/my/requests?page=${page}`);
};

// 사용자 request 업로드
const reqeustUploadItemImage = async ({ file }) => {
  const { data: res } = await getPresignedUrl({ filename: file.name });
  const parsed = res.presignedUrl.split('?');
  const key = 'upload/' + parsed[0].split('upload/')[1];
  const img = new Blob([file]);

  return await fetch(res.presignedUrl, {
    method: 'PUT',
    header: {},
    body: img,
  }).then(async () => {
    return await getReqeustItemImage({
      key: key,
      filename: file.name,
    });
  });
};

// 사용자 request 업로드
const getReqeustItemImage = ({ key, filename }) => {
  return privateApi.post(
    `/api/upload-request`,
    {
      action: 'upload-comment',
      params: {
        key: key,
      },
    },
    {
      headers: {
        'Content-Type': 'application/json',
      },
    }
  );
};

// 사용자 request 작성
const requestAdd = params => {
  return privateApi.post(
    `/api/v3_1/request-add`,
    { ...params },
    {
      headers: {
        'Content-Type': 'application/json',
      },
    }
  );
};

// 사용자 request 수정화면
const getMyRequestDetail = ({ oid }) => {
  return privateApi.get(`api/my/requests/${oid}`);
};

// 사용자 리뷰 수정
const requestEdit = params => {
  return privateApi.post(
    `/api/v3_1/request-edit`,
    { ...params },
    {
      headers: {
        'Content-Type': 'application/json',
      },
    }
  );
};

// 사용자 리뷰 삭제
const deleteRequest = oId => {
  return privateApi.post(`/api/v3_1/request-delete?oid=${oId}`);
};

// 리뷰 리스트
const getReviews = ({ page, sort }) => {
  if (isLoginUsingToken) {
    return privateApi.get(`/api/review?page=${page}&sort=${sort}`);
  } else {
    return instance.get(`/api/review?page=${page}&sort=${sort}`);
  }
};

// 리뷰 화면
const getReview = ({ oid, subject, page, isLogin }) => {
  if (isLogin) {
    return privateApi.get(`/api/review/${oid}/${subject}?page=${page}`);
  } else {
    return instance.get(`/api/review/${oid}/${subject}?page=${page}`);
  }
};

// 리뷰 좋아요, 좋아요 취소
const reviewLikeBtn = oidTarget => {
  return privateApi.post(`/api/review/oid-like?oidTarget=${oidTarget}`);
};

// 리뷰 댓글 작성
const createReviewComment = params => {
  return privateApi.post(
    `/api/v3_1/oid-comment-write-review`,
    { ...params },
    {
      headers: {
        'Content-Type': 'application/json',
      },
    }
  );
};

// 리뷰 댓글 수정
const editReviewComment = params => {
  return privateApi.post(
    `/api/v3_1/oid-comment-edit-review`,
    { ...params },
    {
      headers: {
        'Content-Type': 'application/json',
      },
    }
  );
};

// 리뷰 댓글 삭제
const deleteReviewComment = params => {
  return privateApi.post(
    `/api/v3_1/oid-comment-delete-review`,
    { ...params },
    {
      headers: {
        'Content-Type': 'application/json',
      },
    }
  );
};

// 리뷰 댓글 답글 작성
const createReviewCommentReply = params => {
  return privateApi.post(
    `/api/v3_1/oid-comment-reply-review`,
    { ...params },
    {
      headers: {
        'Content-Type': 'application/json',
      },
    }
  );
};

// 리퀘스트 리스트
const getRequests = ({ page, sort, status, search }) => {
  return instance.get(`/api/request?page=${page}&status=${status}&sort=${sort}&search=${search}`);
};

// 리퀘스트 화면
const getRequest = ({ oid, subject }) => {
  return instance.get(`/api/request/${oid}/${subject}?page=1`);
};

const requestVote = requestId => {
  return privateApi.post(`/api/v3_1/request-vote?oidRequest=${requestId}`);
};

// 리퀘스트 댓글 작성
const createRequestComment = params => {
  return privateApi.post(
    `/api/v3_1/comment-write-request`,
    { ...params },
    {
      headers: {
        'Content-Type': 'application/json',
      },
    }
  );
};

// 리퀘스트 댓글 수정
const editRequestComment = params => {
  return privateApi.post(
    `/api/v3_1/comment-edit-request`,
    { ...params },
    {
      headers: {
        'Content-Type': 'application/json',
      },
    }
  );
};

// 리퀘스트 댓글 삭제
const deleteRequestComment = commentId => {
  return privateApi.post(`/api/v3_1/comment-delete-request?idComment=${commentId}`);
};

// 리퀘스트 댓글 답글 작성
const createRequestCommentReply = params => {
  return privateApi.post(
    `/api/v3_1/comment-reply-request`,
    { ...params },
    {
      headers: {
        'Content-Type': 'application/json',
      },
    }
  );
};

// 장바구니 추가
const createCartAdd = item => {
  return privateApi.post(`/api/v3/cart-add?item=${item}`, {
    headers: {
      'Content-Type': 'application/json',
    },
  });
};

// 장바구니 목록
const getCarts = async () => {
  return privateApi.get(`/api/carts`);
};

// 장바구니 개별삭제
const deleteCartItem = item => {
  return privateApi.post(`/api/v3/cart-delete?item=${item}`, {
    headers: {
      'Content-Type': 'application/json',
    },
  });
};

// 장바구니 전체삭제
const deleteCartAllItem = () => {
  return privateApi.post(`/api/v3/cart-delete-all`, {
    headers: {
      'Content-Type': 'application/json',
    },
  });
};

// 장바구니 > checkout-step1 결제 아이템 정보 추가 하여 조회
const createCheckoutStep1 = async items => {
  return privateApi.get(`/api/checkout-step1?item=${items}`, {
    headers: {
      'Content-Type': 'application/json',
    },
  });
};

// 장바구니 > checkout > 프로모코드 할인 정보 확인
const getPromotionCodeValidate = async code => {
  return privateApi.get(`/api/validate-promotionCode?code=${code}`);
};

// 장바구니 > checkout-step2 결제 아이템 정보 추가 하여 조회
const createCheckoutStep2 = async (credit, code) => {
  return privateApi.post(
    '/api/checkout-step2',
    {
      credit: credit,
      code: code,
    },
    {
      headers: {
        'Content-Type': 'application/json',
      },
    }
  );
  // 2024.03.09
  // return privateApi.get(
  //   `/api/checkout-step2?credit=${credit}&code=${code}&discount=${discount}&subtotal=${subTotal}`,
  //   {
  //     headers: {
  //       'Content-Type': 'application/json',
  //     },
  //   }
  // );
};

// 구매 성공 페이지
const createCheckoutStep3 = async orderNumber => {
  return privateApi.get(`/api/checkout/${orderNumber}`);
};

// 아이템 상세페이지 > 프리티켓으로 item 구매시 페이지 이동
const createCheckoutFree = item => {
  return privateApi.get(`/api/checkout_free?item=${item}`);
};

// free_checkout 페이지에서 프리티켓으로 구매
const postCheckoutFree = item => {
  return privateApi.post(`/api/v3_1/checkout-free-ticket?item=${item}`);
};

// 스페셜 디자이너 목록 조회
const getDesigner = ({ page }) => {
  return instance.get(`/api/designer?page=${page}`);
};
// 스페셜 디자이너 아이템 조회
const getDesignerItems = ({ category_id, page }) => {
  return instance.get(`/api/designers?id=${category_id}&page=${page}`);
};
// 스페셜 디자이너 아이템 조회 NEW
const getDesignerItemsNew = ({ url, page }) => {
  return instance.get(`/api/designers/${url}?page=${page}`);
};

// my point > 사용자 point 조회 & 히스토리 조회
const getPoints = page => {
  return privateApi.get(`/api/my/point?page=${page}`);
};

// my point > 결제 생성 (stripe)
const createStripeInPoint = async (action, params) => {
  return privateApi.post(
    `/api/v3_1/my/point`,
    { query: { action, params } },
    {
      headers: {
        'Content-Type': 'application/json',
      },
    }
  );
};

// my point > 결제 완료 (stripe)
const createCompleteStripeInPoint = async params => {
  return privateApi.post(
    `/api/stripe/point/process`,
    { ...params },
    {
      headers: {
        'Content-Type': 'application/json',
      },
    }
  );
};

// my point > 결제 생성 (paypal)
const createPaypalInPoint = async amount => {
  // console.log('*** [point] step1. api 호출 params 값 : ', amount);
  return privateApi.post(
    `/api/paypal/point/create`,
    { amount },
    {
      headers: {
        'Content-Type': 'application/json',
      },
    }
  );
};

// my point > 결제 (paypal)
const createCompletePaypalInPoint = async params => {
  // console.log('*** [point] step2. api 호출 params 값 : ', params);
  return privateApi.post(`/api/paypal/point/process`, params, {
    headers: {
      'Content-Type': 'application/json',
    },
  });
};

// checkout 결제 생성, 진행
const createCheckoutPayment = async (action, params) => {
  // console.log('*** [checkout] api 호출 action 값 : ', action);
  // console.log('*** [checkout] api 호출 params 값 : ', params);
  return privateApi.post(
    `/api/v3_1/checkout-step2`,
    { query: { action, params } },
    {
      headers: {
        'Content-Type': 'application/json',
      },
    }
  );
};

// 초대 토큰 조회
const getInviteToken = () => {
  return privateApi.get(`/api/invite`);
};

// 초대 토큰 유효성 체크 (일반 회원가입)
const validInvite = token => {
  return instance.get(`/api/validInvite?token=${token}`);
};

// 검색
const getSearch = (searchType, search, page) => {
  return instance.get(`/api/search?${searchType}=${search}${page > 1 ? `&page=${page}` : ''}`);
};

const getSearchV2 = (searchType, search, page) => {
  return privateApi.get(`/api/v2/search?${searchType}=${search}${page > 1 ? `&page=${page}` : ''}`);
};

// 사용자 IP 및 기타 정보
const getRequestInfo = () => {
  return instance.get(`/api/requestInfo`);
};

// 2.0 웹페이지
// 헤더 배너
const getHeaderBanner = () => {
  return instance.get(`/api/v2/headerInfo`);
};

// 홈 점보트론
const getHomeJumbotron = () => {
  return instance.get(`/api/v2/jumbotron`);
};

//점보트론 이미지 사이즈 조정된 점포트론
const getHomeJumbotron2 = () => {
  return instance.get(`/api/v2/jumbotron2`);
};

// 홈 정보 조회
const getHomeInfo = () => {
  return instance.get(`/api/v2/homeInfo`);
};

// 홈 정보 상세 조회
const getHomeInfoDetail = (location, isLogin) => {
  if (isLogin) {
    return privateApi.get(`/api/v2/home/${location}`);
  } else {
    return instance.get(`/api/v2/home/${location}`);
  }
};

const getHomeInfoDetailSub = (location, idCategory, isLogin) => {
  if (isLogin) {
    return privateApi.get(`/api/v2/home/${location}/${idCategory}`);
  } else {
    return instance.get(`/api/v2/home/${location}/${idCategory}`);
  }
};

const postHomeInfoDetailSub = (location, idCategory, isLogin, params) => {
  if (isLogin) {
    return privateApi.post(`/api/v2/home/${location}/${idCategory}`, params);
  } else {
    return instance.post(`/api/v2/home/${location}/${idCategory}`, params);
  }
};

// 스트라이프 결제 skip 기능을 위한 유저 확인
const getConfirm = () => {
  return privateApi.post(`/api/confirm`);
};

//신규유저 무료 5개 다운로드
const welcomeUserFreeDownload = () => {
  return privateApi.get(`/api/my/downloadsReward`);
};

// 신규배너 API
const getTopBanner = async () => {
  try {
    return await instance.get('/api/v2/headerInfo');
  } catch (error) {
    console.error(error);
  }
};
const getMainBannerAd1 = async () => {
  try {
    return await instance.get('/api/v2/home/adv1');
  } catch (error) {
    console.error(error);
  }
};
const getMainBannerAd2 = async () => {
  try {
    return await instance.get('/api/v2/home/adv2');
  } catch (error) {
    console.error(error);
  }
};
const getMainBannerAd3 = async () => {
  try {
    return await instance.get('/api/v2/home/adv3');
  } catch (error) {
    console.error(error);
  }
};
const getMainBannerAd4 = async () => {
  try {
    return await instance.get('/api/v2/home/adv4');
  } catch (error) {
    console.error(error);
  }
};
const getMainBannerAd5 = async () => {
  try {
    return await instance.get('/api/v2/home/adv5');
  } catch (error) {
    console.error(error);
  }
};

const getSectionBanner1 = async isLogin => {
  try {
    if (isLogin) {
      return await privateApi.get('/api/v2/home/collection1');
    } else {
      return await instance.get('/api/v2/home/collection1');
    }
  } catch (error) {
    console.error(error);
  }
};

const getSectionBanner2 = async isLogin => {
  try {
    if (isLogin) {
      return await privateApi.get('/api/v2/home/collection2');
    } else {
      return await instance.get('/api/v2/home/collection2');
    }
  } catch (error) {
    console.error(error);
  }
};

const getMainVideos = async () => {
  try {
    return await instance.get('/api/v2/home/videos');
  } catch (error) {
    console.error(error);
  }
};

const getDesignerFollowers = async (studioId, requestBody) => {
  try {
    return await instance.post(`/api/v2/designer/${studioId}/followers`, requestBody);
  } catch (error) {
    console.error(error);
  }
};

const getDesignerInfoId = async ({ isLogin, studioId }) => {
  try {
    if (isLogin) {
      return await privateApi.get(`/api/v2/designer/${studioId}/info`);
    } else {
      return await instance.get(`/api/v2/designer/${studioId}/info`);
    }
  } catch (error) {
    console.error(error);
  }
};

const getDesignerInfoName = async ({ isLogin, designerURL }) => {
  try {
    if (isLogin) {
      return await privateApi.get(`/api/v2/designer/${designerURL}`);
    } else {
      return await instance.get(`/api/v2/designer/${designerURL}`);
    }
  } catch (error) {
    console.error(error);
  }
};

const getDesignerFollow = async designerURL => {
  try {
    return await privateApi.get(`/api/v2/designer/${designerURL}/follow`);
  } catch (error) {
    console.error(error);
  }
};
const getDesignerUnFollow = async designerURL => {
  try {
    return await privateApi.get(`/api/v2/designer/${designerURL}/unfollow`);
  } catch (error) {
    console.error(error);
  }
};

const getDesignerModels = ({ isLogin, designerURL, requestBody }) => {
  try {
    if (isLogin) {
      return privateApi.post(`/api/v2/designer/${designerURL}/models`, requestBody);
    } else {
      return instance.post(`/api/v2/designer/${designerURL}/models`, requestBody);
    }
  } catch (error) {
    console.error(error);
  }
};

const getDesignerReview = ({ isLogin, designerURL, requestBody }) => {
  try {
    if (isLogin) {
      return privateApi.post(`/api/v2/designer/${designerURL}/reviews`, requestBody);
    } else {
      return instance.post(`/api/v2/designer/${designerURL}/reviews`, requestBody);
    }
  } catch (error) {
    console.error(error);
  }
};

//designers 에 디자이너 리스트 조회
const getDesignerList = async (isLogin, params) => {
  try {
    if (isLogin) {
      return privateApi.post('/api/v2/designers', params);
    } else {
      return instance.post('/api/v2/designers', params);
    }
    // return instance.post('/api/v2/designers', params);
  } catch (error) {
    console.error(error);
  }
};

const getDesignerJumbotron = async () => {
  try {
    return await instance.get('/api/v2/jumbotron/designer');
  } catch (error) {
    console.error(error);
  }
};

//디자이너 페이지 - 댓글
const getDesignerComments = async (studioId, page) => {
  try {
    return await instance.get(`/api/v2/designer/${studioId}/comment?page=${page}`);
  } catch (error) {
    console.error(error);
  }
};

//디자이너 페이지 - 댓글쓰기
const postDesignerCommentsWrite = async (studioId, params) => {
  try {
    return await privateApi.post(`/api/v2/designer/${studioId}/comment-write-item`, {
      action: 'itemComment-write',
      params,
    });
  } catch (error) {
    console.error(error);
  }
};

const postDesignerCommentsEditWrite = async (studioId, params) => {
  try {
    return await privateApi.post(`/api/v2/designer/${studioId}/comment-edit-item`, {
      action: 'itemComment-edit',
      params: params,
    });
  } catch (error) {
    console.error(error);
  }
};

const deleteDesignerComment = (studioId, oidComment) => {
  return privateApi.post(`/api/v2/designer/${studioId}/comment-delete-item`, {
    action: 'itemComment-delete',
    params: {
      oidComment,
    },
  });
};

const getItemdetailOthers = async (id, isLoginState) => {
  try {
    if (isLoginState) {
      return await privateApi.get(`/api/v2/item/detail/${id}/others`);
    } else {
      return await instance.get(`/api/v2/item/detail/${id}/others`);
    }
  } catch (error) {
    console.error(error);
  }
};

const getItemdetailRelated = async (id, isLoginState) => {
  try {
    if (isLoginState) {
      return await privateApi.get(`/api/v2/item/detail/${id}/related`);
    } else {
      return await instance.get(`/api/v2/item/detail/${id}/related`);
    }
  } catch (error) {
    console.error(error);
  }
};

const getItemDetailV2 = async (productId, isLogin) => {
  try {
    if (isLogin) {
      return await privateApi.get(`/api/v2/item/detail/${productId}`);
    } else {
      return await instance.get(`/api/v2/item/detail/${productId}`);
    }
  } catch (error) {
    console.error(error);
  }
};

const postAddfiles = async ({ params }) => {
  try {
    return await privateApi.post(`/api/v2/manage/model/files-add`, { params: params });
  } catch (error) {
    console.error(error);
  }
};

const getDesignerManageModel = async (id, params) => {
  try {
    return await privateApi.get(`/api/v2/manage/${id}/models`, { params });
  } catch (error) {
    console.error(error);
  }

  // try {
  //   return await privateApi.get(`/api/v2/manage/${id}/models`);
  // } catch (error) {
  //   console.error(error);
  // }
};

const getDesignerManageCategory = async () => {
  try {
    return await privateApi.get(`/api/v2/manage/category`);
  } catch (error) {
    console.error(error);
  }
};

const uploadManageImg = async ({ file, idItem, prefix }) => {
  const params = { filename: file.name, prefix: prefix };
  const { data: res } = await getManageImgUrl({ params });
  const parsed = res.presignedUrl.split('?');
  const key = 'upload/' + parsed[0].split('upload/')[1];
  const img = new Blob([file]);

  return await fetch(res.presignedUrl, {
    method: 'PUT',
    header: {},
    body: img,
  }).then(async () => {
    return await getManageImage({
      key: key,
      idItem,
    });
  });
};
const getManageImgUrl = ({ params }) => {
  return privateApi.post(`/api/v2/manage/upload-url`, {
    action: 'image-upload',
    params: params,
  });
};

const getManageImage = ({ key, idItem }) => {
  return privateApi.post(
    `/api/v2/manage/item-upload-image?itemId=${idItem}`,
    {
      action: 'image-upload',
      params: {
        key: key,
      },
    },
    {
      headers: {
        'Content-Type': 'application/json',
      },
    }
  );
};

const deleteManageModel = async (studioId, idItem) => {
  try {
    return await privateApi.post(
      `/api/v2/manage/model/delete?studioId=${studioId}&idItem=${idItem}`
    );
  } catch (error) {
    console.error(error);
  }
};

const getEditModelInfo = async editManageId => {
  try {
    return await privateApi.get(`/api/v2/manage/${editManageId}`);
  } catch (error) {
    console.error(error);
  }
};

const uploadManageFile = async ({ file, idItem }) => {
  const { data: res } = await getManageFileUrl({ filename: file.name });
  const parsed = res.presignedUrl.split('?');
  const key = 'upload/' + parsed[0].split('upload/')[1];
  const img = new Blob([file]);

  return await fetch(res.presignedUrl, {
    method: 'PUT',
    header: {},
    body: img,
  }).then(async () => {
    return await getManagefile({
      key: key,
      idItem,
    });
  });
};

const getManageFileUrl = ({ filename }) => {
  return privateApi.post(`/api/v2/manage/upload-url`, {
    action: 'file-upload',
    params: { prefix: 'modelFile', filename },
  });
};

const getManagefile = ({ key, idItem }) => {
  return privateApi.post(
    `api/v2/manage/item-upload-file?itemId=${idItem}`,
    {
      action: 'upload-file',
      params: {
        key: key,
      },
    },
    {
      headers: {
        'Content-Type': 'application/json',
      },
    }
  );
};

const postManageModelEdit = params => {
  return privateApi.post(`/api/v2/manage/model-edit`, params, {
    headers: {
      'Content-Type': 'application/json',
    },
  });
};

const postManageModelAdd = params => {
  return privateApi.post(`/api/v2/manage/model-add`, params, {
    headers: {
      'Content-Type': 'application/json',
    },
  });
};

const postManageModelDraft = studioId => {
  return privateApi.post(`/api/v2/manage/draft-model-add?studioId=${studioId}`);
};

const deleteModelFile = params => {
  return privateApi.post(`/api/v2/manage/model/file-delete`, params, {
    headers: {
      'Content-Type': 'application/json',
    },
  });
};

const postModelFileAdd = params => {
  return privateApi.post(`/api/v2/manage/model/files-add`, params, {
    headers: {
      'Content-Type': 'application/json',
    },
  });
};

const postFilesList = id => {
  return privateApi.post(`/api/v2/manage/model/file-list?idItem=${id}`);
};

const postDnSub = (itemId, params) => {
  return privateApi.post(`/api/dn/${itemId}/sub`, params, {
    headers: {
      'Content-Type': 'application/json',
    },
  });
};

const getSubDownload = (itemId, jobId) => {
  return privateApi.post(`/api/dn/${itemId}/sub-download?jobId=${jobId}`, null, {
    responseType: 'blob',
  });
};

const postSubDownloadStatus = (itemId, jobId) => {
  return privateApi.post(`/api/dn/${itemId}/status?jobId=${jobId}`);
};

export {
  report,
  getMainBanner,
  getMainSlide,
  getSideSlide,
  getCategories,
  findCategoryDetail,
  getNotice,
  getPrinters,
  getSlicers,
  getTags,
  getStudio,
  getRandomReviews,
  getNewItems,
  getPopularItems,
  searchItems,
  getPresignedUrl,
  uploadItemImage,
  createItemComment,
  deleteItemComment,
  editItemComment,
  replyItemComment,
  getCollectionList,
  getCollectionItems,
  getCollectionItemsNew,
  getCollectionDetail,
  getItemDetail,
  getItemInfo,
  getItemLike,
  getItemOwn,
  isItemLike,
  toggleItemLike,
  getItemInfoV2,
  getFreeLimit,
  getItems,
  getItemReview,
  getItemComments,
  getItemFreeTicketCount,
  getOrders,
  getOrderDetail,
  getUserProfile,
  downloadItem,
  getMyTickets,
  getMyReviews,
  getMyReviewDetail,
  login,
  googleLogin,
  appleLogin,
  githubLogin,
  yahooLogin,
  twitterLogin,
  facebookLogin,
  updateUser,
  updateSubscribe,
  updatePrinter,
  downloadPrinter,
  verifyEmail,
  getSalse,
  selectSales,
  yearlySales,
  getBalanceHistory,
  getProductSales,
  getProductDetail,
  requestWithdrawal,
  emailRequestWithdrawal,
  getDownloads,
  getSelectItems,
  reviewAdd,
  getSelectLikes,
  likesDelete,
  deleteReview,
  reviewEdit,
  getMyRequest,
  ReviewUploadItemImage,
  reqeustUploadItemImage,
  requestAdd,
  getMyRequestDetail,
  requestEdit,
  deleteRequest,
  userDelete,
  getReviews,
  getReview,
  reviewLikeBtn,
  createReviewComment,
  editReviewComment,
  deleteReviewComment,
  createReviewCommentReply,
  getRequests,
  getRequest,
  requestVote,
  createRequestComment,
  editRequestComment,
  deleteRequestComment,
  createRequestCommentReply,
  createCartAdd,
  getCarts,
  deleteCartItem,
  deleteCartAllItem,
  getDesigner,
  getDesignerItems,
  getDesignerItemsNew,
  createCheckoutStep1,
  createCheckoutFree,
  postCheckoutFree,
  getPromotionCodeValidate,
  signup,
  snsSignup,
  getUserInfo,
  logout,
  resetEmail,
  getPoints,
  createStripeInPoint,
  createCompleteStripeInPoint,
  createPaypalInPoint,
  createCompletePaypalInPoint,
  createCheckoutPayment,
  createCheckoutStep2,
  createCheckoutStep3,
  getInviteToken,
  getSearch,
  validateVerifyEmail,
  resendVerifyEmail,
  resetPassword,
  validInvite,
  getRequestInfo,
  getHeaderBanner,
  getHomeJumbotron,
  getHomeInfo,
  getHomeInfoDetail,
  getHomeInfoDetailSub,
  postHomeInfoDetailSub,
  getConfirm,
  getHomeJumbotron2,
  welcomeUserFreeDownload,
  getTopBanner,
  getMainBannerAd1,
  getMainBannerAd2,
  getMainBannerAd3,
  getMainBannerAd4,
  getMainBannerAd5,
  getSectionBanner1,
  getSectionBanner2,
  getMainVideos,
  getDesignerFollowers,
  getDesignerFollow,
  getDesignerUnFollow,
  getDesignerInfoName,
  getDesignerInfoId,
  getDesignerModels,
  getDesignerReview,
  getSelectReviewItems,
  getDesignerList,
  getDesignerJumbotron,
  getDesignerComments,
  postDesignerCommentsWrite,
  postDesignerCommentsEditWrite,
  deleteDesignerComment,
  getItemdetailOthers,
  getItemdetailRelated,
  getItemDetailV2,
  postAddfiles,
  getDesignerManageModel,
  getDesignerManageCategory,
  uploadManageImg,
  deleteManageModel,
  getEditModelInfo,
  uploadManageFile,
  postManageModelEdit,
  deleteModelFile,
  postManageModelAdd,
  postModelFileAdd,
  postFilesList,
  postDnSub,
  getSubDownload,
  postSubDownloadStatus,
  postManageModelDraft,
  getItemsV2,
  getCollectionItemsNewV2,
  getSearchV2,
};
