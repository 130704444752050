import { useContext, useEffect, useState } from 'react';
import Layout from '../../component/layout/Layout';
import Categoryist from './component/CategoryList';
import './Category.css';
import Pagination from './component/Pagination';
import CollectionList from './component/CollectionList';
import { getCategories, getCollectionList, getFreeLimit } from '../../action/request';
import UserContext from '../../context/user/user';
import { ls } from '../../constant';
import CollectionAds from '../GoogleAds/CollectionAds';
import styled from 'styled-components';
import Pagenation from '../../component/designers/Pagenation';
import GoogleAds from '../../component/adv/GoogleAds';
const Collection = ({}) => {
  const [categories, setCategories] = useState([]);
  const [page, setPage] = useState(Number(localStorage.getItem(ls.collectionPage)) || 1);
  const [limit, setLimit] = useState({});
  const [collections, setCollections] = useState({ count: 0 });
  const { state } = useContext(UserContext);
  const mobile = 830;
  const [isMobile, setIsMobile] = useState(window.innerWidth < mobile);
  const [showAds, setShowAds] = useState(window.innerWidth > 1280);

  useEffect(() => {
    fetchData();

    // 콜렉션 아이템 리스트 페이지네이션 초기화
    localStorage.removeItem(ls.collectionListPage);
  }, []);

  // 로그인 상태 변경때마다 fetchData() 호출
  useEffect(() => {
    fetchData();
  }, [state.isLogin]);

  useEffect(() => {
    window.addEventListener('resize', () => {
      setShowAds(window.innerWidth > 1280);
    });

    return () => {
      window.removeEventListener('resize', () => {});
    };
  }, []);

  const fetchData = async () => {
    try {
      const { data: categories } = await getCategories();
      setCategories(categories.data);
      updateListPage(1);

      const { data: resLimit } = await getFreeLimit();
      setLimit(resLimit.data);

      updateListPage(page);
    } catch (error) {}
  };

  const pageChange = async value => {
    updateListPage(value);
  };

  const pageConfirm = value => {
    updateListPage(value);
  };

  const updateListPage = async value => {
    setPage(value);
    localStorage.setItem(ls.collectionPage, value);

    const { data: collectionList } = await getCollectionList({ page: value });
    setCollections(collectionList.data);
  };

  useEffect(() => {
    window.addEventListener('resize', () => {
      setIsMobile(window.innerWidth < mobile);
    });
    return () => {
      window.removeEventListener('resize', () => {
        setIsMobile(window.innerWidth < mobile);
      });
    };
  }, []);

  return (
    <>
      <Layout>
        <CollectionBox id='main' isMobile={isMobile}>
          <div className='page-top page-top-with-breadcrumb'>
            <div className='container container-lg'>
              <h1 className='page-title'>COLLECTIONS</h1>
            </div>
          </div>
          <AdBox className='main-content-with-aside'>
            {/* <Categoryist current={'Collections'} data={categories.items} /> */}
            <div className='main-content'>
              <section className='store-collection'>
                <div className='container'>
                  <div
                    className='content-section content-section-has-top-nav'
                    style={{ minHeight: '900px' }}
                  >
                    <div className='featured-collections'>
                      <div className='featured-collections-list'>
                        {/* <CollectionAds /> */}
                        {showAds && <GoogleAds type='typeD' />}

                        <div>
                          <CollectionList data={collections.collections} />
                        </div>
                      </div>
                    </div>
                    {/* <Pagination
                      page={page}
                      total={Math.ceil(collections.count / 5)}
                      onpagechange={pageChange}
                      onconfirm={pageConfirm}
                    /> */}

                    {collections.count > 0 && (
                      <Pagenation
                        count={collections.count}
                        setPage={setPage}
                        page={page}
                        setPageNation={pageChange}
                        viewCount={5}
                        value={'collectionList'}
                        margin={isMobile ? '80px 0px' : '30px 0px'}
                        isMobile={isMobile}
                      />
                    )}
                  </div>
                </div>
              </section>
            </div>
          </AdBox>
        </CollectionBox>
      </Layout>
    </>
  );
};

export default Collection;

const AdBox = styled.div`
  .featured-collections-list {
    display: flex;
    gap: 48px;
  }

  .main-content {
    margin-left: 0 !important;
  }

  @media screen and (max-width: 1070px) {
    .featured-collections-list {
      gap: 0px;
      display: block;
    }
  }
`;

const CollectionBox = styled.div`
  .container {
    max-width: 1570px !important;
    margin: 0 auto;
  }

  @media screen and (max-width: 830px) {
    margin-top: 20px !important;

    .page-title {
      font-size: 24px !important;
    }
  }
`;
