/* eslint-disable jsx-a11y/anchor-is-valid */
import { useContext, useEffect, useState, useRef, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import Layout from '../../component/layout/Layout';
import ModalContext from '../../context/modal/modal';
import './ItemDetail.css';
import {
  createCartAdd,
  getItemComments,
  getItemDetailV2,
  getItemInfo,
  getItemReview,
  getMyTickets,
  getStudio,
  isItemLike,
  toggleItemLike,
  getItemOwn,
} from '../../action/request.js';

import { useParams, useLocation } from 'react-router-dom';
import CartContext from '../../context/cart.js/carts';
import { gtagAddToCart, gtagViewItemDetailPage } from '../../action/gTag';
import { ls } from '../../constant';
import CommentImageViewer from '../../component/comment/CommentImageViewer';

import styled, { css } from 'styled-components';
import { useRecoilState, useSetRecoilState } from 'recoil';
import {
  paginationAtom,
  preDesignerListAtom,
  designerListAtom,
  scrollRefAtom,
} from '../../atom/atom.js';
import { DESIGNERS } from '../../route/constant.js';
import { removeSpecialCharactersAndSpaces } from '../../component/section/util.js';
import { updateLikeState } from '../../util/updateLikeState.js';
import axios from 'axios';
import ItemDetailNav from './component/ItemDetailNav.jsx';
import ItemDetailInfo from './component/ItemDetailInfo.jsx';
import ItemDetailComment from './component/ItemDetailComment.jsx';
import ItemDetailReview from './component/ItemDetailReview.jsx';
import ItemDetailRelatedModel from './component/ItemDetailRelatedModel.jsx';
import TopContent from './component/TopContent.jsx';
import useWindowDimensions from '../../util/useWindowDimensions.jsx';
import UserContext from '../../context/user/user.js';
import ToTheTop from '../Main/component/ToTheTop.jsx';
import useIsIOS from '../../util/useIsIOS.js';
import Selectefile from '../../component/selectefile/Selectefile.jsx';
import GoogleAds from '../../component/adv/GoogleAds.jsx';

const ItemDetail = () => {
  const navRef = useRef([]);
  const navigate = useNavigate();

  const isIOS = useIsIOS();
  const setPreDesignerList = useSetRecoilState(preDesignerListAtom);
  const [scrollRef, setScrollRef] = useRecoilState(scrollRefAtom);
  const [selectedTab, setSelectedTab] = useState(0);
  const [itemLikeCount, setItemLikeCount] = useState(0);
  const [detail, setDetail] = useState({});
  const [studio, setStudio] = useState({});
  const [itemData, setItemData] = useState({});
  const [hasOwn, setHasOwn] = useState(false);
  const [isLike, setIsLike] = useState(false);
  const [review, setReviews] = useState([]);
  const [reviewCount, setReviewCount] = useState(0);
  const [reviewPage, setReviewPage] = useState(scrollRef?.page ? scrollRef?.page : 1);
  const [comments, setComments] = useState([]);
  const [commentCount, setCommentCount] = useState(0);
  const [commentPage, setCommentPage] = useState(1);
  const [commentTotalPage, setCommentTotalPage] = useState(1);
  const [freeTicketCount, setFreeTicketCount] = useState();
  const [iframeSrc, setIframeSrc] = useState([]);
  const [desc, setDesc] = useState();
  const [showImgViewer, setShowImgViewer] = useState(false);
  const [imgData, setImgData] = useState({ index: null, data: {} });
  const [isMobile, setIsMobile] = useState();
  const [downloadModalStep, setDownloadModalStep] = useState(0);
  const [isLoading, setIsLoading] = useState(true);
  const [designerList, setDesignerList] = useRecoilState(designerListAtom);
  const [showScrollToTop, setShowScrollToTop] = useState(false);
  const [selectedModal, setSelectedModal] = useState(false);
  const [selectedId, setSelectedId] = useState();
  const [selectedName, setSelectedName] = useState();
  const [refreshEvent, setRefreshEvent] = useState();

  const [, setLikeState] = useState();
  const { action } = useContext(ModalContext);
  const { state: cartsState, action: cartsAction } = useContext(CartContext);
  const { id } = useParams();
  const [, setIndex] = useRecoilState(paginationAtom);

  const token = localStorage.getItem('accessToken');
  const isLogin = token ? true : false;

  const index = useLocation();
  const { state: location } = useLocation();
  const fromDesignerSection = location?.location === 'fromDesignerSection';
  const [sectionTab, setSectionTab] = useState(false);

  const { width } = useWindowDimensions();
  const { state } = useContext(UserContext);

  const addBag = async designerId => {
    if (!isLogin) {
      action.setIsLogin(true);
    } else {
      try {
        const { data: res } = await createCartAdd(id);
        if (res) {
          // 기존 카트 갯수에 +1 을 한다.
          cartsAction.changeCartsCount(cartsState.cartsCount + 1);
          localStorage.setItem(ls.cartsCount, cartsState.cartsCount + 1);
          alert(res.result);

          const list = designerList?.filter(
            item => Number(item.designer.id_studio) === Number(designerId)
          );

          if (list) {
            const newCards = list[0].items.map(card =>
              card.product_id === id
                ? {
                    ...card,
                    in_cart: !card.in_cart,
                  }
                : card
            );
            const result = designerList?.map(item =>
              Number(item.designer.id_studio) === Number(designerId)
                ? {
                    designer: item.designer,
                    items: newCards,
                  }
                : item
            );

            setDesignerList(result);
          }
          // gtag 장바구니 추가 태그
          gtagAddToCart({ value: Number(detail.price), itemId: id });
        }
      } catch (e) {}
    }
  };

  // 좋아요 버튼 클릭
  const onClickLikeButton = async designerId => {
    if (!isLogin) {
      action.setIsLogin(true);
    } else {
      const { data } = await toggleItemLike({
        idItem: id,
      });

      if (designerList) {
        const list = designerList?.filter(
          item => Number(item.designer.id_studio) === Number(designerId)
        );

        if (data) {
          const updatedCards = await updateLikeState({
            id: id,
            setLikeState: setLikeState,
            cards: list[0]?.items,
            count: itemLikeCount,
            data: data,
          });

          const result =
            updatedCards &&
            designerList?.map(item =>
              Number(item.designer.id_studio) === Number(designerId)
                ? {
                    designer: item.designer,
                    items: updatedCards,
                  }
                : item
            );
          setDesignerList(result);
        }
      }
      // // 사용자가 좋아요 눌렀는지 상태 확인
      getUserLikeState(id);
    }
  };

  const fetchData = async () => {
    try {
      const isLoginState = token ? true : false;

      const detailRequest = getItemDetailV2(id, isLoginState);

      const infoRequest = getItemInfo({ id });

      const [{ data: resDetail }, { data: resInfo }] = await axios.all([
        detailRequest,
        infoRequest,
      ]);

      setDetail(resDetail.item);
      setItemData(resInfo.item);

      if (!scrollRef) {
        setReviewPage(1);
        setSelectedTab(0);
      }

      const { data: resOwn } = await getItemOwn({
        idItem: id,
      });
      setHasOwn(resOwn.result);

      // itemdetail 페이지에 likeCount 없어짐!
      // getLikeCount(id);
      getUserLikeState(id);
      setIsLoading(false);
      reviewPageChangeHandler();
      commentPageHandler();

      //비디오 src 추출
      const desc = resDetail.item.desc;
      const studioId = resDetail.item.studio_id;
      setDesc(desc);

      if (studioId) {
        getStudioInfo(studioId);
      }

      // gtag 아이템 상세페이지 조회 태그
      if (id) {
        gtagViewItemDetailPage(id);
      }

      // 로그인 상태라면 프리티켓 조회
      if (token) {
        const {
          data: { data: freeTickets },
        } = await getMyTickets();

        setFreeTicketCount(freeTickets?.ticketCount);
      } else {
        setFreeTicketCount(0);
      }
    } catch (error) {
      // console.log(error);
    }
  };

  useEffect(() => {
    if (index.state) {
      setIndex(index.state);
    }

    if (Number(scrollRef?.id) === Number(id)) {
      setTimeout(() => window.scrollTo({ top: scrollRef.offsetTop }), 800);
    } else {
      setSelectedTab(0);
      setReviewPage(1);
    }

    if (isIOS && scrollRef === false) {
      window.scrollTo(0, 0);
    }

    fetchData();
    return () => {
      setItemData({});
    };
  }, [isLogin, id, state.isLogin, refreshEvent]);

  // 사용자가 좋아요 눌렀는지 상태 확인
  const getUserLikeState = async idItem => {
    const { data: getUserLike } = await isItemLike({ idItem });
    // 좋아요 상태 업그레이드
    setIsLike(getUserLike.result);
  };

  const srcList = useMemo(() => {
    if (desc) {
      const iframeRegex = /<iframe[^>]*src="([^"]*)"[^>]*>/g;
      const matches = desc.matchAll(iframeRegex);
      return Array.from(matches, match => match[1]);
    }
    return [];
  }, [desc]);
  useEffect(() => {
    desc && setIframeSrc(srcList);
  }, [srcList]);

  useEffect(() => {
    if (width <= 450) {
      setIsMobile(true);
    } else {
      setIsMobile(false);
    }
  }, [width]);

  useEffect(() => {
    reviewPageChangeHandler();
  }, [reviewPage, isMobile]);

  // 스튜디오 아이디로 스튜디오 정보 가져오기
  const getStudioInfo = async id => {
    const { data: resStudio } = await getStudio({
      studioId: id,
    });

    setStudio(resStudio.data);
  };

  const reviewPageChangeHandler = async () => {
    const size = width <= 450 ? 4 : 8;

    const { data: resItemReview } = await getItemReview({
      idItem: id,
      page: reviewPage,
      size: size,
    });

    setReviews(resItemReview.data.reviews);
    setReviewCount(resItemReview.data.count);
  };

  const commentPageConfirm = page => {
    setCommentPage(page);
  };

  useEffect(() => {
    commentPageHandler();
  }, [commentPage]);

  const commentPageHandler = async () => {
    const { data: resItemComments } = await getItemComments({
      idItem: id,
      page: commentPage,
    });
    const comments = [];
    const results = resItemComments?.data?.comments;
    if (results === null || results === undefined) {
      return setComments([]);
    }
    for (let i = 0; i < results.length; i++) {
      if (results[i]?.id_parent === '0') {
        comments.push(results[i]);
      }
    }
    for (let i = 0; i < results.length; i++) {
      if (results[i]?.id_parent !== '0') {
        for (let j = 0; j < comments.length; j++) {
          if (comments[j].id_comment === results[i].id_parent) {
            comments[j].replys = comments[j].replys ? comments[j].replys : [];
            comments[j].replys.push(results[i]);
          }
        }
      }
    }

    setComments(comments);
    setCommentTotalPage(resItemComments.data.groupCount);
    setCommentCount(resItemComments.data.commentCount);
  };

  const onClickPage = studio => {
    localStorage.setItem('scrollDesigner', 0);
    navigate(`${DESIGNERS}/${removeSpecialCharactersAndSpaces(studio?.name)}`, {
      state: { id: studio?.id_studio },
    });
  };

  useEffect(() => {
    setPreDesignerList(fromDesignerSection);
  }, []);

  useEffect(() => {
    setSectionTab(false);
  }, [id]);

  const onClickNav = index => {
    const offsetTop = navRef.current[index]?.offsetTop || 0;
    const navbarHeight = index === 3 ? 140 : showScrollToTop === false ? 100 : 190; // 네비게이션 바의 높이

    window.scrollTo({
      top: offsetTop - navbarHeight,
      behavior: 'smooth',
    });

    setSelectedTab(index);
    setScrollRef(false);
    setSectionTab(true);
  };
  const moveScroll = index => {
    onClickNav(index);
  };

  function prevScroll() {
    setTimeout(() => window.scrollTo({ top: scrollRef.offsetTop }), 300);
    setScrollRef(false);
    setSelectedTab(scrollRef?.ref);
  }

  const handleScroll = () => {
    if (sectionTab) {
      return;
    }

    if (scrollRef?.ref === 1) {
      prevScroll();
    } else {
      const sections = navRef.current;
      const scrollPosition = window.scrollY + window.innerHeight / 2;

      sections.forEach((section, index) => {
        const sectionTop = section.offsetTop;
        const sectionBottom = sectionTop + section.offsetHeight;
        if (scrollPosition >= sectionTop && scrollPosition < sectionBottom) {
          setSelectedTab(index);
        }
      });
    }
  };

  const resetSectionTab = () => {
    setSectionTab(false);
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    window.addEventListener('wheel', resetSectionTab);
    window.addEventListener('touchmove', resetSectionTab);

    return () => {
      window.removeEventListener('scroll', handleScroll);
      window.removeEventListener('wheel', resetSectionTab);
      window.removeEventListener('touchmove', resetSectionTab);
    };
  }, [navRef, scrollRef, sectionTab]);

  useEffect(() => {
    const handleBeforeUnload = event => {
      const progressModalY = localStorage.getItem('progressModalY');

      if (progressModalY) return;
      window.scrollTo(0, 0);
      setScrollRef(false);
      setSelectedTab(0);
      setRefreshEvent(true);
    };

    window.addEventListener('beforeunload', handleBeforeUnload);

    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
    };
  }, []);

  const onClickDownload = () => {
    if (!isLogin) {
      action.setIsLogin(true);
      return;
    }
    setSelectedId(id);
    setSelectedName(detail?.name);
    setSelectedModal(true);
  };

  return (
    <>
      <PageMobileStyle id='main' step={downloadModalStep}>
        <Layout>
          {showScrollToTop && <ToTheTop />}

          {studio && (
            <div id='main2'>
              <section className='store-detail-section'>
                <TopContent
                  itemData={itemData}
                  studio={studio}
                  detail={detail}
                  isLoading={isLoading}
                  isLike={isLike}
                  freeTicketCount={freeTicketCount}
                  addBag={addBag}
                  onClickLikeButton={onClickLikeButton}
                  onClickDownload={onClickDownload}
                  hasOwn={hasOwn}
                  onClickPage={onClickPage}
                  isMobile={isMobile}
                  isLogin={isLogin}
                  downloadStatus={detail.download_status}
                />

                <div className='item-detail-wrap'>
                  <div className='nav'>
                    {detail && (
                      <ItemDetailNav
                        setSelectedTab={setSelectedTab}
                        selectedTab={selectedTab}
                        commentCount={commentCount}
                        reviewCount={reviewCount}
                        navRef={navRef}
                        onClickNav={onClickNav}
                        detail={detail}
                        freeTicketCount={freeTicketCount}
                        isLogin={isLogin}
                        action={action}
                        setDownloadModalStep={setDownloadModalStep}
                        isMobile={isMobile}
                        loginStatus={state.isLogin}
                        setShowScrollToTop={setShowScrollToTop}
                        priceOption={detail.price_option}
                        onClickDownload={onClickDownload}
                        downloadStatus={detail.download_status}
                        buyStatus={detail.buy_status}
                        isDesigner={detail.isDesigner}
                        isOwn={detail.isOwn}
                      />
                    )}

                    <div
                      ref={el => (navRef.current[0] = el)}
                      className='Details'
                      id='scrollableDiv'
                    >
                      <ItemDetailInfo
                        item={detail}
                        itemDatas={itemData}
                        iframeSrc={iframeSrc}
                        id={id}
                      />
                    </div>
                    <GoogleAds type='typeC' />
                    <div ref={el => (navRef.current[1] = el)} id='Reviews'>
                      <ItemDetailReview
                        reviewCount={reviewCount}
                        reviews={review}
                        moveScroll={moveScroll}
                        setScrollRef={setScrollRef}
                        setReviewPage={setReviewPage}
                        reviewPage={reviewPage}
                        id={detail?.id}
                        offsetTop={navRef?.current[1]?.offsetTop}
                        isMobile={isMobile}
                      />
                    </div>
                    <GoogleAds type='typeC' />
                    <div ref={el => (navRef.current[2] = el)} className='Comments'>
                      <ItemDetailComment
                        id={detail?.id}
                        comments={comments}
                        count={commentTotalPage}
                        page={commentPage}
                        onReload={commentPageHandler}
                        onCommentPageConfirm={commentPageConfirm}
                        setShowImgViewer={setShowImgViewer}
                        setImgData={setImgData}
                        showProduct={detail.status}
                        commentCount={commentCount}
                        setCommentPage={setCommentPage}
                        moveScroll={moveScroll}
                      />
                    </div>
                    <GoogleAds type='typeC' />
                    <div ref={el => (navRef.current[3] = el)} className='Related Models'>
                      {id && (
                        <ItemDetailRelatedModel
                          id={id}
                          state={isLogin}
                          studio={studio.name}
                          categoryName={detail.category_name}
                          scrollRef={scrollRef}
                          itemDataPage={true}
                        />
                      )}
                    </div>
                    <GoogleAds type='typeC' marginBottom={50} />
                  </div>
                </div>
              </section>
            </div>
          )}
        </Layout>
      </PageMobileStyle>
      {showImgViewer && (
        <CommentImageViewer imgData={imgData} setShowImgViewer={setShowImgViewer} />
      )}

      {selectedId && selectedModal && (
        <Selectefile
          setSelectedModal={setSelectedModal}
          id={selectedId}
          setSelectedId={setSelectedId}
          selectedName={selectedName}
        />
      )}
    </>
  );
};

export default ItemDetail;

const PageMobileStyle = styled.div`
  ${props =>
    props.step !== 0 &&
    css`
      height: 100vh;
      overflow: hidden;
    `}
  /* STL다운로드 모달 640px 이하에서 띄울 시 스크롤바 없애기 */
   @media screen and (max-width: 640px) {
    ${props =>
      props.step !== 0 &&
      css`
        display: none;
      `}
  }

  .item-detail-wrap {
    margin: 50px auto 0 auto;
    /* margin: 0 auto; */
    max-width: 1340px;
    padding: 0 1.5rem;
  }

  section.store-detail-section {
    overflow: inherit !important;
    padding: 40px 0 0 0 !important;
  }

  .title {
    color: #171717;
    font-size: 24px;
    font-weight: 600;
    margin-bottom: 10px;
  }
`;
