import { useEffect, useState } from 'react';
import {
  ButtonItem,
  ButtonStyle,
  DesignerActionModalContainer,
  DesignerActionModalContent,
  DesignerActionModalTitle,
  Overlay,
} from './styles/manageModalStyle';

const ManageModal = ({
  modalTitle,
  modalContent,
  titleBold,
  contentFontSize,
  btnContents,
  modalIsOpen,
  setModalIsOpen,
  handleButtonClick,

  setIsAbled,
  isAbled,
  setTopBackBtn,
}) => {
  const handleClick = e => {
    if (
      e.target.textContent === 'Close' ||
      e.target.textContent === 'Cancel' ||
      e.target.textContent === 'close'
    ) {
      setModalIsOpen(false);
      setIsAbled && setIsAbled(false);
      if (setTopBackBtn) {
        setTopBackBtn(false);
      }
    } else {
      handleButtonClick(e);
      // setIsAbled && setIsAbled(true);
    }
  };

  return (
    <>
      {modalIsOpen && (
        <Overlay>
          <DesignerActionModalContainer
            className='DesignerActionModal'
            modalContent={modalContent}
            titleBold={titleBold}
          >
            <DesignerActionModalTitle titleBold={titleBold} modalContent={modalContent}>
              {modalTitle}
            </DesignerActionModalTitle>
            {modalContent && (
              <DesignerActionModalContent
                modalContent={modalContent}
                contentFontSize={contentFontSize}
              >
                {modalContent}
              </DesignerActionModalContent>
            )}

            <ButtonStyle>
              {btnContents?.map((btnContent, index) => {
                const btnvalue =
                  btnContent.title === 'Confirm' || btnContent.title === 'Stop Selling';
                return (
                  <ButtonItem
                    key={index}
                    title={btnContent.title}
                    backgroundColor={btnContent.backgroundColor}
                    color={btnContent.color}
                    onClick={handleClick}
                    disabled={btnvalue && isAbled}
                    btnContents={btnContents?.length}
                  >
                    {btnContent.title}
                  </ButtonItem>
                );
              })}
            </ButtonStyle>
          </DesignerActionModalContainer>
        </Overlay>
      )}
    </>
  );
};

export default ManageModal;
