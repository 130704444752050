import styled from 'styled-components';

const CardAdsBox = () => {
  return (
    <CardAdsBoxContainer>
      <p>CardAdsBox</p>
    </CardAdsBoxContainer>
  );
};

export default CardAdsBox;

const CardAdsBoxContainer = styled.div`
  width: 100%;
  height: 90px;
  background-color: red;
`;

export const Box = styled.div`
  width: 728px;
  height: 90px;
  background-color: red;
`;
