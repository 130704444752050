import { sanitize } from 'dompurify';
import { useContext, useEffect, useState } from 'react';
import CommentBody from '../../../component/comment/CommentBody';
import CommentHeader from '../../../component/comment/CommentHeader';
import CommentReply from '../../../component/comment/CommentReply';
import UserContext from '../../../context/user/user';
import ItemCommentReplyWrite from './ItemCommentReplyWrite';
import { deleteItemComment, editItemComment } from '../../../action/request';
import { RETURN_CODE } from '../../../action/constant';
import Pagenation from '../../../component/designers/Pagenation';

import styled from 'styled-components';

const ItemDetailComment = ({
  id,
  comments,
  count,
  page,
  onCommentPageConfirm,
  onReload,
  setShowImgViewer,
  setImgData,
  showProduct,
  commentCount,
  moveScroll,
}) => {
  const [showReply, setShowReply] = useState([]);
  const [, setPageNation] = useState();
  const { state } = useContext(UserContext);

  const editComment = async ({ comment, msg, medias, imgs }) => {
    try {
      const { data: res } = await editItemComment({
        comment,
        msg,
        medias,
        imgs,
      });
      if (res.returnCode === RETURN_CODE.C00000) {
        onReload();
      }
    } catch (e) {
      alert('request failed');
    }
  };

  const deleteComment = async ({ oidComment }) => {
    try {
      if (window.confirm('Delete?')) {
        const { data: res } = await deleteItemComment({
          oidComment,
        });
        if (res.returnCode === RETURN_CODE.C00000) {
          onReload();
        }
      }
    } catch (e) {
      alert('request failed');
    }
  };

  const showCommentReply = idx => {
    const showList = [...showReply];
    showList[idx] = { ...showList[idx], show: true };
    setShowReply(showList);
    const target = showList[idx]?.target;
    if (target) {
      target.current.scrollIntoView();
    }
  };

  const setCommentReply = (target, idx) => {
    setShowReply(sr => {
      const showList = [...sr];
      showList[idx] = { target, show: false };
      return showList;
    });
  };

  // 리플 박스 모두 닫기 처리
  const resetCommentReply = () => {
    const newArr = [...showReply];
    newArr.forEach(reply => {
      if (reply !== undefined) {
        reply.show = false;
      }
    });
    setShowReply(newArr);
  };

  useEffect(() => {
    resetCommentReply();
  }, [comments]);

  return (
    <Comment>
      <div className='store-detail-tab-content active' id='store_detail_comments'>
        <p className='title'>Comments({commentCount})</p>
        <div className='comment-container'>
          <div className='comments'>
            {state.isLogin && true && showProduct !== 'secret' && (
              <CommentHeader
                thumbnail={state.thumbnail}
                id={id}
                onReload={onReload}
                showProduct={showProduct}
              />
            )}
            <div className='comments-body'>
              <div className='comment-list'>
                <ul>
                  {comments?.map((c, idx) => {
                    return (
                      <li key={c.id_comment}>
                        <div
                          className='comment-list-item'
                          data-json={sanitize(
                            JSON.stringify({
                              comment: c.id_comment,
                              root: c.id_item,
                            })
                          )}
                        >
                          <CommentBody
                            comment={c}
                            pkComment='id_comment'
                            onDelete={() => deleteComment({ oidComment: c.id_comment })}
                            showReply={() => showCommentReply(idx)}
                            onEdit={editComment}
                            setShowImgViewer={setShowImgViewer}
                            setImgData={setImgData}
                            showProduct={showProduct}
                          />
                          {c['replys'] && c['replys'].length > 0 && (
                            <CommentReply
                              replys={c['replys']}
                              showReply={showCommentReply}
                              onEdit={editComment}
                              onDelete={deleteComment}
                              idx={idx}
                              setCommentReply={setCommentReply}
                              pkComment={'id_comment'}
                              setShowImgViewer={setShowImgViewer}
                              setImgData={setImgData}
                              showProduct={showProduct}
                            />
                          )}

                          {showReply[idx]?.show && (
                            <ItemCommentReplyWrite
                              thumbnail={state.thumbnail}
                              onReload={onReload}
                              root={id}
                              parent={c.id_comment}
                            />
                          )}
                        </div>
                      </li>
                    );
                  })}
                </ul>

                {count > 0 && (
                  <Pagenation
                    count={count}
                    setPage={onCommentPageConfirm}
                    page={page}
                    moveScroll={moveScroll}
                    setPageNation={setPageNation}
                    viewCount={3}
                    margin={'60px 0px'}
                    value={'comment'}
                  />
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </Comment>
  );
};

export default ItemDetailComment;

const Comment = styled.div`
  margin-top: 70px;

  @media (max-width: 900px) {
    margin-top: 54px;
  }
  border-bottom: 1px solid rgb(216, 216, 216);

  .comment-container {
    margin: 0 auto;
    max-width: 1340px;
    position: relative;
  }

  .btn-wrap {
    display: flex;
    justify-content: flex-end;
    margin-bottom: 80px;
  }

  .write-comment {
    background-color: #000;
    color: #fff;
    font-size: 18px;
    font-weight: 600;

    width: 12rem;
    height: 52px;
    border-radius: 4px;
    margin-bottom: 15px;
  }
`;
