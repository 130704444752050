import styled from 'styled-components';

const CollectionAds = () => {
  return (
    <CollectionAdsContainer className='collection-ads'>
      <p>CollectionAds</p>
    </CollectionAdsContainer>
  );
};

export default CollectionAds;

const CollectionAdsContainer = styled.div`
  min-width: 160px;
  background-color: red;
  margin-bottom: 6.25rem;

  @media screen and (max-width: 1070px) {
    display: none;
  }
`;

export const AdBoxMobile = styled.div`
  width: 100%;
  height: 50px;
  background-color: red;
  margin-bottom: 34px;

  @media screen and (min-width: 1070px) {
    display: none;
  }
`;
