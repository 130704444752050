import styled from 'styled-components';

export const CollectionGrid = styled.div`
  display: ${props => (props.dataCount === 0 ? 'block' : 'grid')};

  grid-template-columns: repeat(4, 1fr);
  column-gap: 20px;
  row-gap: 70px;

  @media (max-width: 700px) {
    grid-template-columns: 1fr;
    column-gap: 20px;
    row-gap: 23px;
  }
`;

export const BannerWrapper = styled.div`
  grid-column: 1 / -1;
  width: 100%;
  margin: ${props => (props.margin ? props.margin : '0')};
`;
