import React, { useEffect, useState } from 'react';
import { Navigation, Autoplay } from 'swiper/modules';
import { Swiper, SwiperSlide } from 'swiper/react';
import { getHomeInfoDetail } from '../../action/request';
import { replicateObject } from '../../util/skeleton';
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';
import { theme } from '../../styles/theme';
import { SUCCEEDED_FETCH_COUNT } from '../../page/Main/Main2';
import { ImgStyled } from '../../styles/ImgStyled';
import { emptyCard } from '../section/constant';

function AdSponsor({ data, isMobile }) {
  const emptyCards = replicateObject(emptyCard, 1);
  const [cards, setCards] = useState(emptyCards);
  const [isSkeletonLoading, setIsSkeletonLoading] = useState(true);
  const SKELETON_PADDINGTOP =
    '49.664%'; /* 이미지의 가로-세로 비율에 따라 조정 (세로 / 가로 * 100) */
  const options = {
    slidesPerView: 3.2,
    centeredSlides: false,
    spaceBetween: 8,
    loop: true,

    autoplay: {
      delay: 0,
      pauseOnMouseEnter: false,
      disableOnInteraction: false,
    },

    speed: 5000,

    // observer: true,
    // observeParents: true,

    navigation: {
      prevEl: '.buttonSwiperPrevSL1',
      nextEl: '.buttonSwiperNextSL2',
    },
    breakpoints: {
      440: {
        slidesPerView: 3.5,
      },
      700: {
        slidesPerView: 5,
      },
      900: {
        slidesPerView: 7,
      },
      1180: {
        slidesPerView: 8,
      },
    },
  };

  const goTo = (e, path) => {
    e.preventDefault();
    e.stopPropagation();

    window.open(path, '_blank');
  };

  const fetchData = async () => {
    try {
      const { data: detailData } = await getHomeInfoDetail(data.location);
      setCards(detailData.data);
    } catch (e) {}
  };

  useEffect(() => {
    fetchData();
  }, []);

  // swiper는 슬라이드 목록이 없을 때 autoplay, navigation 동작을 시작하지 않는 오류가 생기기 때문에 slide 값이 있을 때 동작하게 한다.
  if (!cards?.length) return null;

  return (
    <div className='sponsor'>
      <div className='centerWrap'>
        <div className='titleRow'>
          <div className='title'>{data?.title}</div>
        </div>
        <div className='sponsorList'>
          <Swiper className='sponsorListBody1' {...options} modules={[Navigation, Autoplay]}>
            <div className='swiper-wrapper'>
              {cards.map((card, idx) => (
                <SwiperSlide key={idx}>
                  <div className='swiper-slide' onClick={e => goTo(e, card.url)}>
                    <ImgStyled src={card.img} className='partinerImg' alt='' />
                  </div>
                </SwiperSlide>
              ))}
            </div>
          </Swiper>
          <button
            tabIndex='0'
            type='button'
            className='buttonSwiperPrev buttonSwiperPrevSL1'
            title='prev'
            style={{ opacity: 0 }}
          ></button>
          <button
            tabIndex='0'
            type='button'
            className='buttonSwiperNext buttonSwiperNextSL2'
            title='next'
            style={{ opacity: 0 }}
          ></button>
        </div>
      </div>
    </div>
  );
}

export default AdSponsor;

// <div className='sponsor'>
// <div className='centerWrap'>
//   <div className='titleRow'>
//     <div className='title'>
//       {!isSkeletonLoading ? (
//         data.title
//       ) : (
//         <Skeleton
//           width={isMobile ? 200 : 800}
//           baseColor={theme.skeleton_dark_gray}
//           highlightColor={theme.sleleton_dark_gray_highlight}
//         />
//       )}
//     </div>
//   </div>

//   <div className='sponsorList'>
//     <Swiper className='sponsorListBody1' {...options} modules={[Navigation, Autoplay]}>
//       <div className='swiper-wrapper'>
//         {sponsors.map((sponsor, idx) => (
//           <SwiperSlide key={idx}>
//             <div className='swiper-slide' onClick={e => goTo(e, sponsor.url)}>
//               {!isSkeletonLoading ? (
//                 <img src={sponsor.img} className='partinerImg' alt='' />
//               ) : (
//                 <Skeleton
//                   style={{
//                     paddingTop: SKELETON_PADDINGTOP,
//                     height: 0,
//                   }}
//                   baseColor={theme.skeleton_dark_gray}
//                   highlightColor={theme.sleleton_dark_gray_highlight}
//                 />
//               )}
//             </div>
//           </SwiperSlide>
//         ))}
//       </div>
//     </Swiper>
//     <button
//       tabIndex='0'
//       type='button'
//       className='buttonSwiperPrev buttonSwiperPrevSL1'
//       title='prev'
//       style={{ opacity: 0 }}
//     ></button>
//     <button
//       tabIndex='0'
//       type='button'
//       className='buttonSwiperNext buttonSwiperNextSL2'
//       title='next'
//       style={{ opacity: 0 }}
//     ></button>
//   </div>
// </div>
// </div>
