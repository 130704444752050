import styled from 'styled-components';

export const ManageEditSection = styled.section`
  background-color: #f5f5f5;
  margin-bottom: 30px;
  padding: 40px 15px;
  border-radius: 8px;
  position: relative;

  .gray {
    font-size: 14px;
    font-weight: 400;
    color: #9a9a9a;
  }

  .section-wrap {
    margin-top: 30px;
  }

  .errorContent {
    font-size: 14px;
    color: #ff0000;
  }

  .errorFlex {
    display: flex;
    justify-content: flex-end;
    margin-top: 10px;
  }

  .editor {
    margin-top: 10px;

    .ql-toolbar {
      border-top-right-radius: 10px !important;
      border-top-left-radius: 10px !important;
      background-color: #fff;
    }

    .ql-container {
      border-bottom-left-radius: 10px;
      border-bottom-right-radius: 10px;
      background-color: #fff;
    }
  }

  .title {
    font-size: 30px;
    font-weight: 500;
  }
  .input-wrap {
    margin-top: 20px;
  }

  .container-wrap {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 10px;
    margin-bottom: 10px;
  }

  .container-title {
    font-size: 20px;
    font-weight: 400;
  }

  .btn-wrap {
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: flex-end;
    justify-content: space-between;
    .btn-left {
      display: flex;
      align-items: flex-end;
    }

    .btn-width {
      width: 240px;
    }

    .color-gray {
      color: #9a9a9a;
      margin-left: 10px;
    }
  }

  .price-wrap {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 30px;
  }

  .price-info {
    font-size: 14px;
    display: flex;
    justify-content: flex-end;
    margin-top: 6px;
  }

  .cover-img {
    margin-top: 30px;

    .img-wrap {
      border: 1px solid #dfdfdf;
      border-radius: 10px;
      max-width: 300px;
      padding: 20px;
      box-sizing: border-box;

      display: flex;
      flex-direction: row;
      justify-content: space-between;
      .img-cover {
        display: flex;
        flex-direction: column;
        align-items: center;

        .image-container:hover .img-count {
          opacity: 0;
        }
      }
      .img-count {
        font-size: 14px;
        font-weight: 400;
        color: ${props => props.color || '#000'};
      }
    }
  }

  .detail-img {
    margin-top: 30px;
    .detail-img-wrap {
      border: 1px solid #dfdfdf;
      border-radius: 10px;
      width: 100%;
      padding: 20px;

      display: grid;
      grid-template-columns: repeat(auto-fill, minmax(110px, 1fr));
      grid-row-gap: 30px;
      grid-column-gap: 10px;
    }
  }

  .ctg-wrap {
    margin-top: 30px;
    .drop-box {
      margin-top: 10px;
      max-width: 400px;
    }
  }

  .file-wrap {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    gap: 10px;
    margin-top: 10px;
    margin-bottom: 20px;
  }
`;

export const ButtonWarper = styled.div`
  display: flex;
  justify-content: flex-end;
`;

export const FileBox = styled.div`
  height: 48px;
  border: ${props => props.border || '1px solid #000'};
  border-radius: 10px;
  background: #dfdfdf;
  width: ${props => props.width};

  .modelupload {
    width: 200px;
    height: 100%;
    display: grid;
    grid-template-columns: 70% 30%;
  }

  .selectedfilebox {
    height: 100%;
    display: grid;
    grid-template-columns: 85% 15%;
  }

  @media screen and (max-width: 400px) {
    .selectedfilebox {
      grid-template-columns: 80% 20%;
    }
  }

  .file-name {
    /* overflow-x: auto; */
    overflow-x: hidden;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding: 10px;
    text-align: right !important;
    user-select: none; /* 텍스트 선택 방지 */
    cursor: pointer;

    /* 스크롤바 숨기기 */

    span {
      text-overflow: ellipsis;
      white-space: nowrap;
      font-size: 12px;
      font-family: 'Inter';
      width: 98%;
      height: 100%;
      display: inline-flex;
      align-items: center;
      overflow: hidden;
    }
  }

  .file-size {
    border-left: 1px solid #000;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    background-color: #9a9a9a;
    border-bottom-right-radius: 10px;
    border-top-right-radius: 10px;

    span {
      color: #fff;
      font-size: 12px;
      font-weight: 400;
      font-family: 'Inter';
    }
  }

  .btn-trash {
    /* 여기에 추가 스타일을 넣으세요 */
  }

  .hover-icon {
    width: 25px;
    height: 25px;
  }
`;

export const DndWrap = styled.div`
  .img-count {
    font-size: 14px;
    font-weight: 400;
    margin-top: 9px;
    color: ${props => props.color || '#000'};
    display: flex;
    justify-content: center;
  }

  .button-container {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 4px;

    background-color: rgb(245, 245, 245);

    width: 100%;
    opacity: 0;
    transition: opacity 0.3s ease;
    position: absolute;
    bottom: -28px;
  }

  .image-container:hover .button-container {
    opacity: 1;
  }

  .image-container:hover .img-count {
    opacity: 0;
  }

  .button {
    width: 103%;
    height: 25px;
    background-color: #ffffff;
    border-radius: 4px;

    display: flex;
    justify-content: center;
    align-items: center;
  }
  .hover-icon {
    width: 24px;
    height: 24px;
  }
`;

export const DndImg = styled.div`
  position: relative;
  cursor: pointer;
  margin-bottom: 10px;
  border: 1px solid;
  border-radius: 10px;
  border-color: ${props => props.border || '#000'};

  height: ${props => props.height || 75}px;

  img {
    width: 100%;
    height: 100%;
    border-radius: 10px;
    aspect-ratio: 1/1;
  }
  &:hover {
    background: rgba(255, 255, 255, 0.8);
  }
  &:hover div {
    opacity: 1;
  }

  .overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(255, 255, 255, 0);
    display: flex;
    justify-content: center;
    align-items: center;
    transition: background-color 0.3s ease;
    opacity: 0;
    border-radius: 10px;
  }

  &:hover .overlay {
    background-color: rgba(255, 255, 255, 0.8);
  }
`;

export const HoverIcon = styled.div`
  position: absolute;
  color: white;
  border-radius: 50%;
  padding: 5px;
  opacity: 0;
  transition: opacity 0.3s ease;

  img {
    width: 25px;
    height: 25px;
  }
`;

export const PreviewImg = styled.div`
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;

  z-index: 100;

  .img-wrap {
    position: relative;
    padding: 10px;
    background-color: #fff;
  }

  .zoom-img {
    width: 800px;
  }

  .btn-close {
    position: absolute;
    top: 0px;
    right: 0px;
    background-color: #fff;
    padding: 5px 10px;
    margin-top: 5px;
  }

  .icon {
    width: 20px;
  }
`;

export const StatusValue = styled.span`
  color: ${props => props.color};
`;
