import React, { useEffect, useState, useContext } from 'react';
import { getDesignerModels, toggleItemLike, createCartAdd } from '../../../action/request';
import ModelCard from '../../common/itemCards/ModelCard';
import Pagenation from '../Pagenation';

import { CardWrap, Card } from '../designers';
import { useRecoilState } from 'recoil';
import { designerPaginationAtom, designerListAtom } from '../../../atom/atom';
import UserContext from '../../../context/user/user';
import ModalContext from '../../../context/modal/modal';
import CartContext from '../../../context/cart.js/carts';
import { ls } from '../../../constant';
import { gtagAddToCart } from '../../../action/gTag';
import { ImgStyled } from '../../../styles/ImgStyled';
import useClickHandler from '../../common/itemCards/useCardClickHandeler';
import Selectefile from '../../selectefile/Selectefile';
import useDownload from '../../../hook/useDownload';

const Models = ({ moveScroll, selectedSort, designerURL, selectedTab }) => {
  const { oneClick, twoClick, handleClick } = useClickHandler();
  const {
    selectedId,
    selectedName,
    selectedModal,
    onClickDownload,
    setSelectedModal,
    setSelectedId,
  } = useDownload();
  const [page, setPage] = useState(1);
  const [data, setData] = useState();
  const [count, setCount] = useState();
  const [pageNation, setPageNation] = useRecoilState(designerPaginationAtom);
  const [isLoading, setIsLoading] = useState(true);
  const { state: userState } = useContext(UserContext);
  const [designerList, setDesignerList] = useRecoilState(designerListAtom);

  const isLogin = userState.isLogin;
  const { action: modalAction } = useContext(ModalContext);
  const { state: cartsState, action: cartsAction } = useContext(CartContext);

  useEffect(() => {
    setIsLoading(true);
    if (pageNation) {
      setPage(pageNation);
      fetchModels(pageNation);
    } else {
      fetchModels(page);
    }
  }, [page, selectedSort, isLogin, designerURL, selectedTab]);

  const fetchModels = async index => {
    try {
      const requestBody = {
        page: Number(index),
        sortType: selectedSort.value,
      };
      const { data: designerData } = await getDesignerModels({
        isLogin,
        designerURL,
        requestBody,
      });
      const resultData = designerData.data;
      setData(resultData.list);
      setCount(resultData.pagingInfo.total);

      setTimeout(() => {
        setIsLoading(false);
      }, 500);
    } catch (error) {
      console.error(error);
      setTimeout(() => {
        setIsLoading(false);
      }, 300);
    }
  };

  const clickLike = async (e, id, designerId) => {
    e.stopPropagation();
    if (!userState.isLogin) {
      modalAction.setIsLogin(true);
    } else {
      const { data: toggleLike } = await toggleItemLike({
        idItem: id,
      });

      if (toggleLike.result) {
        // card state에 좋아요 +1, 내 좋아요 상태 값 true

        const newCards = data?.map(card =>
          card.product_id === id
            ? {
                ...card,
                product_like_count: `${Number(card.product_like_count) + 1}`,
                my_like: !card.my_like,
              }
            : card
        );
        setData(newCards);
        if (designerList) {
          const updateList = designerList?.map(item =>
            Number(item.designer.id_studio) === Number(designerId)
              ? {
                  designer: item.designer,
                  items: newCards,
                }
              : item
          );
          setDesignerList(updateList);
        }
      } else {
        // card state에 좋아요 -1, 내 좋아요 상태 값 false
        const newCards = data?.map(card =>
          card.product_id === id
            ? {
                ...card,
                product_like_count: `${Number(card.product_like_count) - 1}`,
                my_like: !card.my_like,
              }
            : card
        );
        setData(newCards);

        if (designerList) {
          const updateList = designerList?.map(item =>
            Number(item.designer.id_studio) === Number(designerId)
              ? {
                  designer: item.designer,
                  items: newCards,
                }
              : item
          );
          setDesignerList(updateList);
        }
      }
    }
  };

  const clickCart = async (e, price, id, inCart, designerId) => {
    e.stopPropagation();
    // 이미 카트에 담겨있을 경우
    if (inCart) return;

    if (!userState.isLogin) {
      // 로그인이 안되어 있을 때 로그인 모달을 보여준다.
      modalAction.setIsLogin(true);
    } else {
      try {
        const { data: res } = await createCartAdd(id);
        if (res) {
          // 기존 카트 갯수에 +1 을 한다.
          cartsAction.changeCartsCount(cartsState.cartsCount + 1);
          localStorage.setItem(ls.cartsCount, cartsState.cartsCount + 1);

          alert(res.result);

          // 기존 카드 상태에 카트 값 업데이트
          const newCards = data.map(card =>
            card.product_id === id
              ? {
                  ...card,
                  in_cart: !card.in_cart,
                }
              : card
          );
          setData(newCards);

          const designerListUpdata = designerList?.map(item =>
            Number(item.designer.id_studio) === Number(designerId)
              ? {
                  designer: item.designer,
                  items: newCards,
                }
              : item
          );
          setDesignerList(designerListUpdata);

          // gtag 장바구니 추가 태그
          gtagAddToCart({ value: Number(price), itemId: id });
        }
      } catch (e) {
        console.error(e);
      }
    }
  };

  return (
    <div>
      {isLoading ? (
        <div style={{ height: '100vh' }}></div>
      ) : (
        <CardWrap>
          {data?.map((item, key) => {
            return (
              <Card key={key} className={item.product_id}>
                <ModelCard
                  key={key}
                  handleClick={() => handleClick(key)}
                  twoClick={twoClick === key}
                  oneClick={oneClick === key}
                  data={item}
                  clickLike={clickLike}
                  clickCart={clickCart}
                  page={page}
                  onClickDownload={onClickDownload}
                  buyStatus={item.buy_status}
                  downloadStatus={item?.download_status}
                  isDesigner={item.isDesigner && isLogin}
                  isOwn={item?.isOwn && isLogin}
                />
              </Card>
            );
          })}
        </CardWrap>
      )}

      {count > 0 && (
        <Pagenation
          count={count}
          setPage={setPage}
          page={page}
          moveScroll={moveScroll}
          setPageNation={setPageNation}
          viewCount={16}
          value={'Models'}
        />
      )}
      {selectedId && selectedModal && (
        <Selectefile
          setSelectedModal={setSelectedModal}
          id={selectedId}
          setSelectedId={setSelectedId}
          selectedName={selectedName}
          status={true}
        />
      )}
    </div>
  );
};

export default Models;
